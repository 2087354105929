import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../redux/Store';
import axios from 'axios';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { additionalQueryParams } from '../../utils/utilities';
import { setModalContent, setShowModal } from '../utils/utils.slice';
import { PBXType } from '../../types/pbx/pbx.type';

export const postPbxDetails = createAsyncThunk<PBXType, { data: unknown }, { state: RootState }>(
  'pbxs/postPbxDetails',
  async ({ data }, thunkAPI) => {
    try {
      const response = await axios.post<PBXType>(`${process.env.REACT_APP_API_URL}/pbxs`, data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating PBX!');
    }
  }
);

export const getPbxsList = createAsyncThunk<PaginationType<PBXType>, RouteParamsType, { state: RootState }>(
  'pbxs/getPbxsList',
  async (params, thunkAPI) => {
    try {
      const additionalParams = additionalQueryParams(
        thunkAPI.getState().sharedReducer.selectedCompany,
        thunkAPI.getState().sharedReducer.selectedLocation,
        thunkAPI.getState().sharedReducer.includeSuborgs,
        thunkAPI.getState().sharedReducer.selectedCountry
      );
      const mergedParams = { ...params, ...additionalParams };
      const queryString = Object.keys(mergedParams)
        .map(key => key + '=' + mergedParams[key])
        .join('&');

      const response = await axios.get<PaginationType<PBXType>>(
        `${process.env.REACT_APP_API_URL}/pbxs${queryString ? `?${queryString}` : ''}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching PBXs!');
    }
  }
);

export const getPbxDetails = createAsyncThunk<PBXType, { id: number }, { state: RootState }>(
  'pbxs/getPbxDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<PBXType>(`${process.env.REACT_APP_API_URL}/pbxs/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching PBX!');
    }
  }
);

export const patchPbxDetails = createAsyncThunk<PBXType, { id: number; data: unknown }, { state: RootState }>(
  'pbxs/patchPbxDetails',
  async ({ data, id }, thunkAPI) => {
    try {
      const response = await axios.patch<PBXType>(`${process.env.REACT_APP_API_URL}/pbxs/${id}`, data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating PBX!');
    }
  }
);

export const deletePbxDetails = createAsyncThunk<PBXType, { id: number; fetchList: boolean }, { state: RootState }>(
  'pbxs/deletePbxDetails',
  async ({ id, fetchList }, thunkAPI) => {
    try {
      const response = await axios.delete<PBXType>(`${process.env.REACT_APP_API_URL}/pbxs/${id}`);
      if (fetchList) thunkAPI.dispatch(getPbxsList(thunkAPI.getState().pbxsReducer.pbxsRouteParams));
      thunkAPI.dispatch(setShowModal(false));
      thunkAPI.dispatch(setModalContent(null));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting PBX!');
    }
  }
);

export const getPbxFixDialRule = createAsyncThunk<PBXType, { id: number }, { state: RootState }>(
  'pbxs/getPbxFixDialRule',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<PBXType>(`${process.env.REACT_APP_API_URL}/pbxs/fixDialRule/${id}/`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fixing pbx dial rule!');
    }
  }
);
