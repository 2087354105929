import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../redux/Store';
import axios from 'axios';
import {
  ACSATAProfileDetailsType,
  ACSATAProfileType,
  UpdateACSATAProfileDto,
} from '../../types/ata/acs-ata/acs-ata-profile/acs-ata-profile.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { PaginationType } from '../../types/utils/pagination.type';
import { ATATypeType } from '../../types/ata/ata-type/ata-type.type';
import { AcsFirmwareType } from '../../types/ata/acs-ata/acs-firmware/acs-firmware.type';
import { setShowModal, setModalContent } from '../utils/utils.slice';
import { ACSATALogType, ATALogType } from '../../types/ata/ata-log/ata-log.type';
import { FaxType } from '../../types/fax/fax.type';
import { ATAListItemType } from '../../types/ata/ATAListItem.type';
import { additionalQueryParams } from '../../utils/utilities';
import { ACSATAType } from '../../types/ata/acs-ata/acs-ata.type';
import { GrandstreamATAType } from '../../types/ata/grandstream-ata/grandstream-ata.type';
import { ATA_TYPE, ATA_TYPES } from '../../utils/constants';
import { GrandstreamAtaBaseTemplateType } from '../../types/ata/grandstream-ata/grandstream-ata-base-template/grandstream-ata-base-template.type';
import { GrandstreamATAOrgTemplateType } from '../../types/ata/grandstream-ata/grandstream-ata-org-template/grandstream-ata-org-template.type';
import { setATATypeDetails, setATATypeDetailsFetchStatus } from './atas.slice';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { TempFirmware } from '../../views/Main/ATATypes/ATATypeDetails/ATATypeDetails';

// ALL ------------------------------------------------------------------------------------------------

export const getATAsList = createAsyncThunk<PaginationType<ATAListItemType>, RouteParamsType, { state: RootState }>(
  'ata/getATAsList',
  async (params, thunkAPI) => {
    try {
      const additionalParams = additionalQueryParams(
        thunkAPI.getState().sharedReducer.selectedCompany,
        thunkAPI.getState().sharedReducer.selectedLocation,
        thunkAPI.getState().sharedReducer.includeSuborgs,
        thunkAPI.getState().sharedReducer.selectedCountry
      );
      const mergedParams = { ...params, ...additionalParams };
      const queryString = Object.keys(mergedParams)
        .map(key => key + '=' + mergedParams[key])
        .join('&');

      const response = await axios.get<PaginationType<ATAListItemType>>(
        `${process.env.REACT_APP_API_URL}/atas${queryString ? `?${queryString}` : ''}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching ATAs list!');
    }
  }
);

export const getATADetails = createAsyncThunk<
  {
    type: ATA_TYPE;
    details: ACSATAType | GrandstreamATAType;
  },
  { id: number; type: ATA_TYPE },
  { state: RootState }
>('ata/getATADetails', async ({ id, type }, thunkAPI) => {
  try {
    const response = await axios.get<ACSATAType | GrandstreamATAType>(
      `${process.env.REACT_APP_API_URL}/${type === 'ACS' ? 'acs' : 'atas'}/${id}`
    );
    return { type, details: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching ATA!');
  }
});

export const getATAConfigFile = createAsyncThunk<
  { type: ATA_TYPE },
  { id: number; type: ATA_TYPE; sn: string },
  { state: RootState }
>('ata/getATAConfigFile', async ({ id, type, sn }, thunkAPI) => {
  try {
    let url = '',
      mimeType = '';

    if (type === ATA_TYPES.ACS) {
      url = `${process.env.REACT_APP_API_URL}/acs/${id}/file`;
      mimeType = 'octet/stream';
    }

    if (type === ATA_TYPES.GS) {
      url = `${process.env.REACT_APP_API_URL}/atas/${id}/file`;
      mimeType = 'text/xml';
    }

    const response = await axios.get(url);

    const dLink = window.URL.createObjectURL(new Blob([response.data], { type: mimeType }));
    const link = document.createElement('a');
    link.href = dLink;
    link.setAttribute('download', sn.toUpperCase());
    document.body.appendChild(link);
    link.click();

    return { type };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting ata config file!');
  }
});

export const factoryResetATA = createAsyncThunk<
  ATALogType,
  { id: number; type: ATA_TYPE; fetchList: boolean },
  { state: RootState }
>('ata/factoryResetATA', async ({ id, type, fetchList }, thunkAPI) => {
  try {
    const response = await axios.post<ATALogType>(
      `${process.env.REACT_APP_API_URL}/${type === 'ACS' ? 'acs' : 'atas'}/${id}/reset`
    );
    if (fetchList) {
      thunkAPI.dispatch(getATAsList(thunkAPI.getState().atasReducer.atasRouteParams));
    } else {
      thunkAPI.dispatch(getATADetails({ id, type }));
    }

    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during reverted factory settings!');
  }
});

export const updateATAStatus = createAsyncThunk<
  { type: ATA_TYPE },
  { id: number; type: ATA_TYPE; fetchList: boolean },
  { state: RootState }
>('ata/updateATAStatus', async ({ id, type, fetchList }, thunkAPI) => {
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/${type === 'ACS' ? 'acs' : 'atas'}/${id}/current_status`);
    if (fetchList) {
      thunkAPI.dispatch(getATAsList(thunkAPI.getState().atasReducer.atasRouteParams));
    } else {
      thunkAPI.dispatch(getATADetails({ id, type }));
    }
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return { type };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during refreshing ATA status!');
  }
});

export const rebootATA = createAsyncThunk<
  ATALogType,
  { id: number; type: ATA_TYPE; fetchList: boolean },
  { state: RootState }
>('ata/rebootATA', async ({ id, type, fetchList }, thunkAPI) => {
  try {
    const response = await axios.post<ATALogType>(
      `${process.env.REACT_APP_API_URL}/${type === 'ACS' ? 'acs' : 'atas'}/${id}/reboot`
    );
    if (fetchList) {
      thunkAPI.dispatch(getATAsList(thunkAPI.getState().atasReducer.atasRouteParams));
    } else {
      thunkAPI.dispatch(getATADetails({ id, type }));
    }

    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during rebooting!');
  }
});

export const deleteATADetails = createAsyncThunk<
  { type: ATA_TYPE; fetchList: boolean },
  { id: number; type: ATA_TYPE; fetchList: boolean },
  { state: RootState }
>('ata/deleteATADetails', async ({ id, type, fetchList }, thunkAPI) => {
  try {
    await axios.delete(`${process.env.REACT_APP_API_URL}/${type === 'ACS' ? 'acs' : 'atas'}/${id}`);
    if (fetchList) thunkAPI.dispatch(getATAsList(thunkAPI.getState().atasReducer.atasRouteParams));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return { type, fetchList };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during removing ATA!');
  }
});

export const patchATADetails = createAsyncThunk<
  { type: ATA_TYPE },
  { id: number; type: ATA_TYPE; data: unknown; additionalQuery?: string },
  { state: RootState }
>('ata/patchATADetails', async ({ id, type, data, additionalQuery }, thunkAPI) => {
  try {
    await axios.patch(
      `${process.env.REACT_APP_API_URL}/${type === 'ACS' ? 'acs' : 'atas'}/${id}${
        additionalQuery ? additionalQuery : ''
      }`,
      data
    );
    return { type };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during changing ATA!');
  }
});

export const postATADetails = createAsyncThunk<
  { type: ATA_TYPE },
  { type: ATA_TYPE; data: unknown },
  { state: RootState }
>('ata/postATADetails', async ({ type, data }, thunkAPI) => {
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/${type === 'ACS' ? 'acs' : 'atas'}`, data);
    return { type };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating ATA!');
  }
});

export const postATAs = createAsyncThunk<{ type: ATA_TYPE }, { type: ATA_TYPE; data: unknown }, { state: RootState }>(
  'ata/postATAs',
  async ({ type, data }, thunkAPI) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/${type === 'ACS' ? 'acs' : 'atas'}/bulk`, data);
      return { type };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating ATA!');
    }
  }
);

// ACS ------------------------------------------------------------------------------------------------

export const getATAACSAdvancedPortSettings = createAsyncThunk<unknown, { id: number }, { state: RootState }>(
  'acs-ata/getATAACSAdvancedPortSettings',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<unknown>(`${process.env.REACT_APP_API_URL}/acs/${id}/portConfig/`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response.data.message || 'Error during getting ACS ATA advanced port settings list!'
      );
    }
  }
);

export const resetATAACSAdvancedPortSettings = createAsyncThunk<
  { success: boolean },
  { id: number; ataID: number; data: unknown },
  { state: RootState }
>('acs-ata/resetATAACSAdvancedPortSettings', async ({ id, ataID, data }, thunkAPI) => {
  try {
    const response = await axios.patch<{ success: boolean }>(
      `${process.env.REACT_APP_API_URL}/acs/${ataID}/${id}/reset/portConfig`,
      data
    );
    thunkAPI.dispatch(getATAACSAdvancedPortSettings({ id }));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during reset ACS ATA port config!');
  }
});

export const patchATAACSAdvancedPortSettings = createAsyncThunk<
  { success: boolean },
  { id: number; ataID: number; data: unknown },
  { state: RootState }
>('acs-ata/patchATAACSAdvancedPortSettings', async ({ id, ataID, data }, thunkAPI) => {
  try {
    const response = await axios.patch<{ success: boolean }>(
      `${process.env.REACT_APP_API_URL}/acs/${ataID}/${id}/portConfig/`,
      data
    );
    thunkAPI.dispatch(getATAACSAdvancedPortSettings({ id }));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during saving ACS ATA port config!');
  }
});

export const getATAACSAdvancedSettings = createAsyncThunk<unknown, { id: number }, { state: RootState }>(
  'acs-ata/getATAACSAdvancedSettings',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<unknown>(`${process.env.REACT_APP_API_URL}/acs/${id}/config/`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response.data.message || 'Error during getting ACS ATA Advanced settings list!'
      );
    }
  }
);

export const resetATAACSAdvancedSettings = createAsyncThunk<
  { success: boolean },
  { id: number; data: unknown },
  { state: RootState }
>('acs-ata/resetATAACSAdvancedSettings', async ({ id, data }, thunkAPI) => {
  try {
    const response = await axios.patch<{ success: boolean }>(
      `${process.env.REACT_APP_API_URL}/acs/${id}/reset/config`,
      data
    );
    thunkAPI.dispatch(getATAACSAdvancedSettings({ id }));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during reset ACS ATA config!');
  }
});

export const patchATAACSAdvancedSettings = createAsyncThunk<
  { success: boolean },
  { id: number; data: unknown },
  { state: RootState }
>('acs-ata/patchATAACSAdvancedSettings', async ({ id, data }, thunkAPI) => {
  try {
    const response = await axios.patch<{ success: boolean }>(
      `${process.env.REACT_APP_API_URL}/acs/${id}/config/`,
      data
    );
    thunkAPI.dispatch(getATAACSAdvancedSettings({ id }));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during saving ACS ATA config!');
  }
});

export const getAcsAtaProfilesList = createAsyncThunk<
  PaginationType<ACSATAProfileType>,
  RouteParamsType,
  { state: RootState }
>('acs-ata/getAcsAtaProfilesList', async (params, thunkAPI) => {
  try {
    const queryString = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');

    const response = await axios.get<Array<ACSATAProfileType>>(
      `${process.env.REACT_APP_API_URL}/acs/profiles${queryString ? `?${queryString}` : ''}`
    );

    return {
      items: response.data || [],
      meta: {
        totalItems: response.data?.length || 0,
        totalPages: 1,
      },
    };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching ACS ATA Profiles list!');
  }
});

export const getAcsAtaProfileDetails = createAsyncThunk<ACSATAProfileDetailsType, { id: number }, { state: RootState }>(
  'acs-ata/getAcsAtaProfileDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<ACSATAProfileDetailsType>(`${process.env.REACT_APP_API_URL}/acs/profiles/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching ACS ATA Profile!');
    }
  }
);

export const patchAcsAtaProfileDetails = createAsyncThunk<
  ACSATAProfileType,
  { id: number; data: UpdateACSATAProfileDto },
  { state: RootState }
>('acs-ata/patchAcsAtaProfileDetails', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<ACSATAProfileType>(`${process.env.REACT_APP_API_URL}/acs/profiles/${id}`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating ACS ATA Profile!');
  }
});

export const postACSFirmware = createAsyncThunk<
  { url: string },
  { data: { version: string; ataType: { id: number }; file: File; no?: number } },
  { state: RootState }
>('acs-ata/postACSFirmware', async ({ data }, thunkAPI) => {
  try {
    const details: ATATypeType = thunkAPI.getState().atasReducer.ataTypeDetails;
    const no = (details?.acs_ata_firmwares?.length || 0) + 1;
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('body', JSON.stringify({ version: data.version, ataType: data.ataType, no }));
    const response = await axios.post<AcsFirmwareType>(`${process.env.REACT_APP_API_URL}/acs/firmware`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    thunkAPI.dispatch(setModalContent(null));
    thunkAPI.dispatch(setShowModal(false));

    const newFirmwares = [...(details?.acs_ata_firmwares || []), response.data];

    thunkAPI.dispatch(setATATypeDetails({ ...(details || {}), acs_ata_firmwares: newFirmwares }));
    thunkAPI.dispatch(setATATypeDetailsFetchStatus(FETCH_STATUS.FULFILLED));

    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during uploading firmware!');
  }
});

export const patchACSFirmware = createAsyncThunk<
  { updated: boolean },
  { data: { isActive?: boolean; version?: string; isLatest?: boolean; no?: number }; id: number },
  { state: RootState }
>('acs-ata/patchACSFirmware', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<{
      updated: boolean;
    }>(`${process.env.REACT_APP_API_URL}/acs/firmware/${id}`, data);

    const details: ATATypeType = thunkAPI.getState().atasReducer.ataTypeDetails;
    let newFirmwares: Array<unknown>;
    if (data.isLatest) {
      newFirmwares = (details.acs_ata_firmwares || []).map(firmware => {
        if (firmware.id === id) {
          return { ...firmware, isLatest: true };
        }
        return { ...firmware, isLatest: false };
      });
    }

    newFirmwares = (details.acs_ata_firmwares || [])?.map(firmware => {
      if (firmware.id === id) {
        return { ...firmware, ...data };
      }

      return firmware;
    });

    thunkAPI.dispatch(setATATypeDetails({ ...details, acs_ata_firmwares: newFirmwares }));
    thunkAPI.dispatch(setATATypeDetailsFetchStatus(FETCH_STATUS.FULFILLED));

    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during uploading firmware!');
  }
});

export const patchATAACSFirmware = createAsyncThunk<
  { updated: boolean },
  { id: number; data: { firmware: { id: number } } },
  { state: RootState }
>('acs-ata/patchATAACSFirmware', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<{
      updated: boolean;
    }>(`${process.env.REACT_APP_API_URL}/acs/${id}/firmware`, data);
    thunkAPI.dispatch(getATADetails({ id, type: ATA_TYPE.ACS }));
    thunkAPI.dispatch(getATAACSAdvancedSettings({ id }));

    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating firmware!');
  }
});

export const deleteATAACSFirmware = createAsyncThunk<AcsFirmwareType, { id: number }, { state: RootState }>(
  'acs-ata/deleteATAACSFirmware',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.delete<AcsFirmwareType>(`${process.env.REACT_APP_API_URL}/acs/firmware/${id}`);
      const details: ATATypeType = thunkAPI.getState().atasReducer.ataTypeDetails;
      const newFirmwares = (details.acs_ata_firmwares || [])?.filter(firmware => firmware.id !== id);
      thunkAPI.dispatch(setATATypeDetails({ ...details, acs_ata_firmwares: newFirmwares }));
      thunkAPI.dispatch(setATATypeDetailsFetchStatus(FETCH_STATUS.FULFILLED));

      thunkAPI.dispatch(setShowModal(false));
      thunkAPI.dispatch(setModalContent(null));

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting firmware!');
    }
  }
);

export const getACSATALogs = createAsyncThunk<
  PaginationType<ACSATALogType>,
  RouteParamsType & { serialNumber: string },
  { state: RootState }
>('acs-ata/getACSATALogs', async (params, thunkAPI) => {
  try {
    const { serialNumber, ...mergedParams } = params;

    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<ACSATALogType>>(
      `${process.env.REACT_APP_API_URL}/acs/${serialNumber}/logs${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching acs ata logs!');
  }
});

export const getACSATALogDetails = createAsyncThunk<ATALogType, { id: number }, { state: RootState }>(
  'acs-ata/getACSATALogDetails/no-loader',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<ATALogType>(`${process.env.REACT_APP_API_URL}/ata-logs/${id}`);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching acs ata log!');
    }
  }
);

export const getACSATALogLatestDetails = createAsyncThunk<ATALogType, { ataId: number }, { state: RootState }>(
  'acs-ata/getACSATALogLatestDetails',
  async ({ ataId }, thunkAPI) => {
    try {
      const response = await axios.get<ATALogType>(`${process.env.REACT_APP_API_URL}/ata-logs/${ataId}/latest`);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching acs ata log!');
    }
  }
);

export const getACSATAFaxes = createAsyncThunk<
  PaginationType<FaxType>,
  RouteParamsType & { mac: string },
  { state: RootState }
>('acs-ata/getACSATAFaxes', async (params, thunkAPI) => {
  try {
    const { mac, ...mergedParams } = params;

    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<FaxType>>(
      `${process.env.REACT_APP_API_URL}/acs/${mac}/faxes${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching faxes!');
  }
});

// GS ------------------------------------------------------------------------------------------------

export const updateAllGSATAsConfigs = createAsyncThunk<{ success: boolean }, void, { state: RootState }>(
  'acs-ata/updateAllGSATAsConfigs',
  async (params, thunkAPI) => {
    try {
      const response = await axios.get<{ success: boolean }>(`${process.env.REACT_APP_API_URL}/atas/updateAllXMLs`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during update all GS ATA configs!');
    }
  }
);

// ATA BASE TEMPLATE ------------------------------------------------------------------------------------------------

export const getATABaseTemplateList = createAsyncThunk<
  PaginationType<GrandstreamAtaBaseTemplateType>,
  RouteParamsType,
  { state: RootState }
>('ata-gs/getATABaseTemplateList', async (params, thunkAPI) => {
  try {
    const additionalParams = additionalQueryParams(
      thunkAPI.getState().sharedReducer.selectedCompany,
      thunkAPI.getState().sharedReducer.selectedLocation,
      thunkAPI.getState().sharedReducer.includeSuborgs
    );
    const mergedParams = { ...params, ...additionalParams };
    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<GrandstreamAtaBaseTemplateType>>(
      `${process.env.REACT_APP_API_URL}/ata-base-templates${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting base template list!');
  }
});

export const getATABaseTemplateDetails = createAsyncThunk<
  GrandstreamAtaBaseTemplateType,
  { id: number },
  {
    state: RootState;
  }
>('ata-gs/getATABaseTemplateDetails', async ({ id }, thunkAPI) => {
  try {
    const response = await axios.get<GrandstreamAtaBaseTemplateType>(
      `${process.env.REACT_APP_API_URL}/ata-base-templates/${id}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting base template details!');
  }
});

export const deleteATABaseTemplateDetails = createAsyncThunk<
  { success: boolean; fetchList: boolean },
  { id: number; fetchList: boolean },
  { state: RootState }
>('ata/deleteATABaseTemplateDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/ata-base-templates/${id}`);
    if (fetchList)
      thunkAPI.dispatch(getATABaseTemplateList(thunkAPI.getState().atasReducer.ataBaseTemplatesRouteParams));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return { ...response.data, fetchList };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during removing base template!');
  }
});

export const postATABaseTemplateDetails = createAsyncThunk<
  { success: boolean },
  { data: unknown },
  { state: RootState }
>('ata/postATABaseTemplateDetails', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/ata-base-templates`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating base template!');
  }
});

export const patchATABaseTemplateDetails = createAsyncThunk<
  { details: { success: boolean }; disableRedirect?: boolean },
  { id: number; data: unknown; disableRedirect?: boolean },
  { state: RootState }
>('ata/patchATABaseTemplateDetails', async ({ id, data, disableRedirect }, thunkAPI) => {
  try {
    const response = await axios.patch(`${process.env.REACT_APP_API_URL}/ata-base-templates/${id}`, data);
    return { details: response.data, disableRedirect };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during changing base template!');
  }
});

export const patchGSBaseConfigDetails = createAsyncThunk<
  { success: boolean },
  { id: number; data: unknown },
  { state: RootState }
>('ata/patchGSBaseConfigDetails', async ({ id, data }, thunkAPI) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/ata-base-templates/gs-base-config/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during changing base template!');
  }
});

// ATA ORG TEMPLATE ------------------------------------------------------------------------------------------------

export const getATAOrgTemplateList = createAsyncThunk<
  PaginationType<GrandstreamATAOrgTemplateType>,
  RouteParamsType,
  { state: RootState }
>('ata-gs/getATAOrgTemplateList', async (params, thunkAPI) => {
  try {
    const additionalParams = additionalQueryParams(
      thunkAPI.getState().sharedReducer.selectedCompany,
      thunkAPI.getState().sharedReducer.selectedLocation,
      thunkAPI.getState().sharedReducer.includeSuborgs,
      thunkAPI.getState().sharedReducer.selectedCountry
    );
    const mergedParams = { ...params, ...additionalParams };
    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<GrandstreamATAOrgTemplateType>>(
      `${process.env.REACT_APP_API_URL}/ata-org-templates${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting org template list!');
  }
});

export const getATAOrgTemplateDetails = createAsyncThunk<
  GrandstreamATAOrgTemplateType,
  { id: number },
  {
    state: RootState;
  }
>('ata-gs/getATAOrgTemplateDetails', async ({ id }, thunkAPI) => {
  try {
    const response = await axios.get<GrandstreamATAOrgTemplateType>(
      `${process.env.REACT_APP_API_URL}/ata-org-templates/${id}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting org template details!');
  }
});

export const deleteATAOrgTemplateDetails = createAsyncThunk<
  { success: boolean; fetchList: boolean },
  { id: number; fetchList: boolean },
  { state: RootState }
>('ata/deleteATAOrgTemplateDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/ata-org-templates/${id}`);
    if (fetchList) thunkAPI.dispatch(getATAOrgTemplateList(thunkAPI.getState().atasReducer.ataOrgTemplatesRouteParams));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return { ...response.data, fetchList };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during removing org template!');
  }
});

export const postATAOrgTemplateDetails = createAsyncThunk<
  { success: boolean },
  { data: unknown },
  { state: RootState }
>('ata/postATAOrgTemplateDetails', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/ata-org-templates`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating org template!');
  }
});

export const patchATAOrgTemplateDetails = createAsyncThunk<
  { details: { success: boolean }; disableRedirect?: boolean },
  { id: number; data: unknown; disableRedirect?: boolean },
  { state: RootState }
>('ata/patchATAOrgTemplateDetails', async ({ id, data, disableRedirect }, thunkAPI) => {
  try {
    const response = await axios.patch(`${process.env.REACT_APP_API_URL}/ata-org-templates/${id}`, data);
    return { details: response.data, disableRedirect };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during changing org template!');
  }
});

export const patchATAOrgTemplateOverridesDetails = createAsyncThunk<
  { success: boolean },
  { id: number; data: unknown },
  { state: RootState }
>('ata/patchGSOrgConfigDetails', async ({ id, data }, thunkAPI) => {
  try {
    const response = await axios.patch(`${process.env.REACT_APP_API_URL}/ata-org-templates/${id}/overrides`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during changing org template!');
  }
});

// ATA TYPES ------------------------------------------------------------------------------------------------

export const getATATypesList = createAsyncThunk<PaginationType<ATATypeType>, RouteParamsType, { state: RootState }>(
  'ata/getATATypesList',
  async (params, thunkAPI) => {
    try {
      const additionalParams = additionalQueryParams(
        thunkAPI.getState().sharedReducer.selectedCompany,
        thunkAPI.getState().sharedReducer.selectedLocation,
        thunkAPI.getState().sharedReducer.includeSuborgs
      );
      const mergedParams = { ...params, ...additionalParams };
      const queryString = Object.keys(mergedParams)
        .map(key => key + '=' + mergedParams[key])
        .join('&');

      const response = await axios.get<PaginationType<ATATypeType>>(
        `${process.env.REACT_APP_API_URL}/atas/types/${queryString ? `?${queryString}` : ''}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting ATA type list!');
    }
  }
);

export const getATATypeDetails = createAsyncThunk<
  ATATypeType,
  { id: number },
  {
    state: RootState;
  }
>('ata/getATATypeDetails', async ({ id }, thunkAPI) => {
  try {
    const response = await axios.get<ATATypeType>(`${process.env.REACT_APP_API_URL}/atas/types/${id}`);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting ATA type details!');
  }
});

export const deleteATATypeDetails = createAsyncThunk<
  { success: boolean; fetchList: boolean },
  { id: number; fetchList: boolean },
  { state: RootState }
>('ata/deleteATATypeDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/atas/types/${id}`);
    if (fetchList) thunkAPI.dispatch(getATATypesList(thunkAPI.getState().atasReducer.ataTypesRouteParams));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return { ...response.data, fetchList };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during removing ATA Type');
  }
});

export const postATATypeDetails = createAsyncThunk<
  ATATypeType,
  { data: unknown; tempFirmware?: TempFirmware },
  {
    state: RootState;
  }
>('ata/postATATypeDetails', async ({ data, tempFirmware }, thunkAPI) => {
  try {
    const response = await axios.post<ATATypeType>(`${process.env.REACT_APP_API_URL}/atas/types/`, data);

    if (tempFirmware) {
      thunkAPI.dispatch(
        postACSFirmware({
          data: {
            ataType: { id: response.data.id },
            file: tempFirmware.file,
            no: 1,
            version: tempFirmware.version,
          },
        })
      );
    }

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating ATA Type!');
  }
});

export const patchATATypeDetails = createAsyncThunk<
  { details: { success: boolean }; disableRedirect?: boolean },
  { id: number; data: unknown; disableRedirect?: boolean },
  { state: RootState }
>('ata/patchATATypeDetails', async ({ id, data, disableRedirect }, thunkAPI) => {
  try {
    const response = await axios.patch(`${process.env.REACT_APP_API_URL}/atas/types/${id}`, data);
    return { details: response.data, disableRedirect };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during changing ATA Type!');
  }
});

export const restunATA = createAsyncThunk<
  ATALogType, // Return type
  { ataId: number; fetchList: boolean }, // Input parameters
  { state: RootState }
>('ata/restunATA', async ({ ataId, fetchList }, thunkAPI) => {
  try {
    const response = await axios.post<ATALogType>(`${process.env.REACT_APP_API_URL}/acs/${ataId}/restun`);

    if (fetchList) {
      thunkAPI.dispatch(getATAsList(thunkAPI.getState().atasReducer.atasRouteParams));
    } else {
      thunkAPI.dispatch(getATADetails({ id: ataId, type: ATA_TYPE.ACS }));
    }

    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));

    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response?.data?.message || 'Error during restun operation!');
  }
});
