import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../redux/Store';
import axios from 'axios';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { setShowModal, setModalContent } from '../utils/utils.slice';
import {
  CreateRevioFieldIdDto,
  RevioFieldIdDetailsType,
  RevioFieldIdListItemType,
  UpdateRevioFieldIdDto,
} from '../../types/tc-service/tc-service-type/revio-field-id/revio-field-id.type';
import { additionalQueryParams } from '../../utils/utilities';
import { TcServiceType } from '../../types/tc-service/tc-service.type';
import { TcServiceTypeType } from '../../types/tc-service/tc-service-type/tc-service-type.type';
import { getOrganizationDetails } from '../organizations/organizations.action';
import { TcServiceTypeOverrideType } from '../../types/tc-service/tc-service-type/tc-service-type-override/tc-service-type-override.type';

export const postRevIoFieldIdDetails = createAsyncThunk<
  RevioFieldIdDetailsType,
  { data: CreateRevioFieldIdDto },
  { state: RootState }
>('tcServiceType/postRevIoFieldIdDetails', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<RevioFieldIdDetailsType>(
      `${process.env.REACT_APP_API_URL}/tc-service-type/revioField`,
      data
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating rev.io field id!');
  }
});

export const getRevIoFieldIdsList = createAsyncThunk<
  PaginationType<RevioFieldIdListItemType>,
  RouteParamsType,
  { state: RootState }
>('tcServiceType/getRevIoFieldIdsList', async (params, thunkAPI) => {
  try {
    const queryString = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');

    const response = await axios.get<PaginationType<RevioFieldIdListItemType>>(
      `${process.env.REACT_APP_API_URL}/tc-service-type/revioField${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching rev.io field ids!');
  }
});

export const getRevIoFieldIdDetails = createAsyncThunk<RevioFieldIdDetailsType, { id: number }, { state: RootState }>(
  'tcServiceType/getRevIoFieldIdDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<RevioFieldIdDetailsType>(
        `${process.env.REACT_APP_API_URL}/tc-service-type/revioField/${id}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching rev.io field id!');
    }
  }
);

export const patchRevIoFieldIdDetails = createAsyncThunk<
  RevioFieldIdDetailsType,
  { id: number; data: UpdateRevioFieldIdDto },
  { state: RootState }
>('tcServiceType/patchRevIoFieldIdDetails', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<RevioFieldIdDetailsType>(
      `${process.env.REACT_APP_API_URL}/tc-service-type/revioField/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating rev.io field id!');
  }
});

export const deleteRevIoFieldIdDetails = createAsyncThunk<
  RevioFieldIdDetailsType,
  { id: number; fetchList: boolean },
  { state: RootState }
>('tcServiceType/deleteRevIoFieldIdDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<RevioFieldIdDetailsType>(
      `${process.env.REACT_APP_API_URL}/tc-service-type/revioField/${id}`
    );
    if (fetchList)
      thunkAPI.dispatch(getRevIoFieldIdsList(thunkAPI.getState().tcServicesReducer.revIoFieldIdsRouteParams));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting rev.io field id!');
  }
});

export const getTcServicesList = createAsyncThunk<PaginationType<TcServiceType>, RouteParamsType, { state: RootState }>(
  'tc-services/getTcServicesList',
  async (params, thunkAPI) => {
    try {
      const additionalParams = additionalQueryParams(
        thunkAPI.getState().sharedReducer.selectedCompany,
        thunkAPI.getState().sharedReducer.selectedLocation,
        thunkAPI.getState().sharedReducer.includeSuborgs,
        thunkAPI.getState().sharedReducer.selectedCountry
      );
      const mergedParams = { ...params, ...additionalParams };
      const queryString = Object.keys(mergedParams)
        .map(key => key + '=' + mergedParams[key])
        .join('&');

      const response = await axios.get<PaginationType<TcServiceType>>(
        `${process.env.REACT_APP_API_URL}/tc-service${queryString ? `?${queryString}` : ''}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching tc services!');
    }
  }
);

export const getTcServiceDetails = createAsyncThunk<TcServiceType, { id: number }, { state: RootState }>(
  'tc-services/getTcServiceDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<TcServiceType>(`${process.env.REACT_APP_API_URL}/tc-service/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching tc service!');
    }
  }
);

export const postCheckRevIoTcService = createAsyncThunk<Array<string>, { id: number }, { state: RootState }>(
  'tc-services/postCheckRevIoTcService',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.post<Array<string>>(
        `${process.env.REACT_APP_API_URL}/tc-service/${id}/check-rev-io`,
        {}
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fixing revio service!');
    }
  }
);

export const postFixRevIoService = createAsyncThunk<string, { id: number }, { state: RootState }>(
  'tc-services/postFixRevIoService',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.post<string>(`${process.env.REACT_APP_API_URL}/tc-service/${id}/fix-rev-io`, {});

      thunkAPI.dispatch(postCheckRevIoTcService({ id }));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fixing revio service!');
    }
  }
);

export const postTcServiceTypeDetails = createAsyncThunk<TcServiceTypeType, { data: unknown }, { state: RootState }>(
  'tc-service/postTcServiceTypeDetails',
  async ({ data }, thunkAPI) => {
    try {
      const response = await axios.post<TcServiceTypeType>(`${process.env.REACT_APP_API_URL}/tc-service-type`, data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating tc service type!');
    }
  }
);

export const getTcServiceTypesList = createAsyncThunk<
  PaginationType<TcServiceTypeType>,
  RouteParamsType,
  { state: RootState }
>('tc-service/getTcServiceTypesList', async (params, thunkAPI) => {
  try {
    const additionalParams = additionalQueryParams(
      thunkAPI.getState().sharedReducer.selectedCompany,
      thunkAPI.getState().sharedReducer.selectedLocation,
      thunkAPI.getState().sharedReducer.includeSuborgs
    );
    const mergedParams = { ...params, ...additionalParams };
    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<TcServiceTypeType>>(
      `${process.env.REACT_APP_API_URL}/tc-service-type${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching tc service types!');
  }
});

export const getTcServiceTypeDetails = createAsyncThunk<TcServiceTypeType, { id: number }, { state: RootState }>(
  'tc-service/getTcServiceTypeDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<TcServiceTypeType>(`${process.env.REACT_APP_API_URL}/tc-service-type/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching tc service type!');
    }
  }
);

export const patchTcServiceTypeDetails = createAsyncThunk<
  TcServiceTypeType,
  { id: number; data: unknown },
  {
    state: RootState;
  }
>('tc-service/patchTcServiceTypeDetails', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<TcServiceTypeType>(
      `${process.env.REACT_APP_API_URL}/tc-service-type/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating tc service type!');
  }
});

export const deleteTcServiceTypeDetails = createAsyncThunk<
  TcServiceTypeType,
  { id: number; fetchList: boolean },
  { state: RootState }
>('tc-service/deleteTcServiceTypeDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<TcServiceTypeType>(`${process.env.REACT_APP_API_URL}/tc-service-type/${id}`);
    if (fetchList)
      thunkAPI.dispatch(getTcServiceTypesList(thunkAPI.getState().tcServicesReducer.tcServiceTypesRouteParams));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting tc service type!');
  }
});

export const getServiceTypeOverrides = createAsyncThunk<
  PaginationType<TcServiceTypeOverrideType>,
  { id: number },
  { state: RootState }
>('tc-service/getServiceTypeOverrides', async ({ id }, thunkAPI) => {
  try {
    const response = await axios.get<PaginationType<TcServiceTypeOverrideType>>(
      `${process.env.REACT_APP_API_URL}/tc-service-type/overrides?org_id=${id}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting service type overrides!');
  }
});

export const patchAllServiceTypeOverride = createAsyncThunk<
  { success: boolean },
  { data: { postArr: Array<any>; patchArr: Array<any>; deleteArr: Array<any>; effective_date: string } },
  { state: RootState }
>(
  'tc-service/patchAllServiceTypeOverride',
  async ({ data: { patchArr, deleteArr, postArr, effective_date } }, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/tc-service-type/override/`;
      const promises: Array<any> = [];
      postArr.forEach(data => {
        promises.push(axios.post(url, { ...data, effective_date }));
      });

      patchArr.forEach(data => {
        promises.push(axios.patch(url + data.id, { ...data, effective_date }));
      });

      deleteArr.forEach(data => {
        promises.push(axios.delete(`${url}${data.id}?effective_date=${effective_date}`));
      });

      await Promise.all(promises);
      thunkAPI.dispatch(
        getOrganizationDetails({ id: thunkAPI.getState().organizationsReducer.organizationDetails.id })
      );

      return { success: true };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during save service type overrides!');
    }
  }
);
