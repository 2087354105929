import { PaginationType } from '../../types/utils/pagination.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { createSlice } from '@reduxjs/toolkit';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import { ACSATAProfileType } from '../../types/ata/acs-ata/acs-ata-profile/acs-ata-profile.type';
import {
  deleteATAACSFirmware,
  deleteATABaseTemplateDetails,
  deleteATADetails,
  deleteATAOrgTemplateDetails,
  deleteATATypeDetails,
  factoryResetATA,
  getACSATAFaxes,
  getACSATALogDetails,
  getACSATALogLatestDetails,
  getACSATALogs,
  getAcsAtaProfileDetails,
  getAcsAtaProfilesList,
  getATAACSAdvancedPortSettings,
  getATAACSAdvancedSettings,
  getATABaseTemplateDetails,
  getATABaseTemplateList,
  getATAConfigFile,
  getATADetails,
  getATAOrgTemplateDetails,
  getATAOrgTemplateList,
  getATAsList,
  getATATypeDetails,
  getATATypesList,
  patchAcsAtaProfileDetails,
  patchACSFirmware,
  patchATAACSAdvancedPortSettings,
  patchATAACSFirmware,
  patchATABaseTemplateDetails,
  patchATADetails,
  patchATAOrgTemplateDetails,
  patchATAOrgTemplateOverridesDetails,
  patchATATypeDetails,
  patchGSBaseConfigDetails,
  postACSFirmware,
  postATABaseTemplateDetails,
  postATADetails,
  postATAOrgTemplateDetails,
  postATAs,
  postATATypeDetails,
  rebootATA,
  resetATAACSAdvancedPortSettings,
  restunATA,
  updateAllGSATAsConfigs,
  updateATAStatus,
} from './atas.action';
import snackbarUtils from '../../utils/SnackbarUtils';
import { ACSATALogType, ATALogType } from '../../types/ata/ata-log/ata-log.type';
import { FaxType } from '../../types/fax/fax.type';
import { ATAListItemType } from '../../types/ata/ATAListItem.type';
import { GrandstreamATAType } from '../../types/ata/grandstream-ata/grandstream-ata.type';
import { ACSATAType } from '../../types/ata/acs-ata/acs-ata.type';
import { ATAOrgTemplateType } from '../../types/ata/ATAOrgTemplate.type';
import { GrandstreamAtaBaseTemplateType } from '../../types/ata/grandstream-ata/grandstream-ata-base-template/grandstream-ata-base-template.type';
import { ATATypeType } from '../../types/ata/ata-type/ata-type.type';

export interface ATAsState {
  acsAtaProfilesList: null | PaginationType<ACSATAProfileType>;
  acsAtaProfilesListFetchStatus: FETCH_STATUS;
  acsAtaProfilesRouteParams: RouteParamsType;
  acsAtaProfileDetails: null | ACSATAProfileType;
  acsAtaProfileDetailsFetchStatus: FETCH_STATUS;
  acsAtaProfileDetailsPatchStatus: FETCH_STATUS;

  // ACS FIRMWARE
  acsFirmwarePostStatus: FETCH_STATUS;
  acsFirmwarePatchStatus: FETCH_STATUS;
  acsFirmwareDeleteStatus: FETCH_STATUS;
  ataACSFirmwarePatchStatus: FETCH_STATUS;

  // LOGS
  acsATALogs: null | PaginationType<ACSATALogType>;
  acsATALogsFetchStatus: FETCH_STATUS;
  acsATALogsRouteParams: RouteParamsType;

  // FAXES
  acsATAFaxes: null | PaginationType<FaxType>;
  acsATAFaxesFetchStatus: FETCH_STATUS;
  acsATAFaxesRouteParams: RouteParamsType;

  // ATAs List
  atasList: null | PaginationType<ATAListItemType>;
  atasListFetchStatus: FETCH_STATUS;
  atasRouteParams: RouteParamsType;

  // ATA DETAILS
  gsATADetails: null | GrandstreamATAType;
  acsATADetails: null | ACSATAType;
  ataDetailsFetchStatus: FETCH_STATUS;
  ataDetailsPostStatus: FETCH_STATUS;
  atasPostStatus: FETCH_STATUS;
  ataDetailsPatchStatus: FETCH_STATUS;
  ataDetailsDeleteStatus: FETCH_STATUS;
  ataConfigFetchStatus: FETCH_STATUS;
  ataStatusPostStatus: FETCH_STATUS;

  // ACS SETTINGS
  ataACSAdvancedPortSettings: null | unknown;
  ataACSAdvancedPortSettingsFetchStatus: FETCH_STATUS;
  ataACSAdvancedPortSettingsPatchStatus: FETCH_STATUS;
  ataACSAdvancedSettings: null | unknown;
  atasBulk: null | Array<unknown>;
  ataACSAdvancedSettingsFetchStatus: FETCH_STATUS;
  ataACSAdvancedSettingsPatchStatus: FETCH_STATUS;

  // ATA BASE TEMPLATE
  ataBaseTemplatesRouteParams: RouteParamsType;
  ataBaseTemplatesList: null | PaginationType<GrandstreamAtaBaseTemplateType>;
  ataBaseTemplatesListFetchStatus: FETCH_STATUS;
  ataBaseTemplateDetails: null | GrandstreamAtaBaseTemplateType;
  ataBaseTemplateDetailsFetchStatus: FETCH_STATUS;
  ataBaseTemplateDetailsDeleteStatus: FETCH_STATUS;
  ataBaseTemplateDetailsPostStatus: FETCH_STATUS;
  ataBaseTemplateDetailsPatchStatus: FETCH_STATUS;
  gsBaseConfigDetailsPatchStatus: FETCH_STATUS;

  // ATA ORG TEMPLATE
  ataOrgTemplatesRouteParams: RouteParamsType;
  ataOrgTemplatesList: null | PaginationType<ATAOrgTemplateType>;
  ataOrgTemplatesListFetchStatus: FETCH_STATUS;
  ataOrgTemplateDetails: null | ATAOrgTemplateType;
  ataOrgTemplateDetailsFetchStatus: FETCH_STATUS;
  ataOrgTemplateDetailsDeleteStatus: FETCH_STATUS;
  ataOrgTemplateDetailsPostStatus: FETCH_STATUS;
  ataOrgTemplateDetailsPatchStatus: FETCH_STATUS;
  ataOrgTemplateOverridesDetailsPatchStatus: FETCH_STATUS;

  // ATA TYPES
  ataTypesRouteParams: RouteParamsType;
  ataTypesList: null | PaginationType<ATATypeType>;
  ataTypesListFetchStatus: FETCH_STATUS;
  ataTypeDetails: null | ATATypeType;
  ataTypeDetailsFetchStatus: FETCH_STATUS;
  ataTypeDetailsDeleteStatus: FETCH_STATUS;
  ataTypeDetailsPostStatus: FETCH_STATUS;
  ataTypeDetailsPatchStatus: FETCH_STATUS;

  // ATA ACTIONS
  ataFactoryResetPostStatus: FETCH_STATUS;
  ataFactoryReset: null | ATALogType;
  ataRebootPostStatus: FETCH_STATUS;
  ataReboot: null | ATALogType;
  ataRestunPostStatus: FETCH_STATUS;
  ataRestun: null | ATALogType;
  ataLogFetchStatus: FETCH_STATUS;
  ataLog: null | ATALogType;
}

export const initialATABaseTemplatesRouteParams = {
  page: 1,
  limit: 10,
  order: '-ata_base_template.id',
  q: '',
};

export const initialATAOrgTemplatesRouteParams = {
  page: 1,
  limit: 10,
  order: '-ata_org_template.id',
  q: '',
};

export const initialACSATALogsRouteParams = {
  page: 1,
  limit: 10,
  order: '-obj.id',
  q: '',
  type: 'all',
};

export const initialATATypesRouteParams = {
  page: 1,
  limit: 10,
  order: '-ata_type.id',
  q: '',
};

export const initialATAsListRouteParams = {
  page: 1,
  limit: 10,
  order: '-ata.id',
  q: '',
  status: '',
  type: '',
  port_status: '',
  equipment_status: 'all',
  warehouse_id: '',
  in_service: 'all',
  country_id: '',
  country_category: '',
  model: '',
  firmware_version: '',
};

export const initialACSATAFaxesRouteParams = {
  page: 1,
  limit: 10,
  order: '-obj.id',
  q: '',
  direction: 'all',
};

export const initialAcsAtaProfilesRouteParams = {
  page: 1,
  limit: 10,
  order: '-acs_ata_profile.id',
  q: '',
};

const INIT_STATE: ATAsState = {
  acsAtaProfilesList: null,
  acsAtaProfilesListFetchStatus: FETCH_STATUS.NULL,
  acsAtaProfilesRouteParams: initialAcsAtaProfilesRouteParams,
  acsAtaProfileDetails: null,
  acsAtaProfileDetailsFetchStatus: FETCH_STATUS.NULL,
  acsAtaProfileDetailsPatchStatus: FETCH_STATUS.NULL,

  // ACS FIRMWARE
  acsFirmwarePostStatus: FETCH_STATUS.NULL,
  acsFirmwarePatchStatus: FETCH_STATUS.NULL,
  acsFirmwareDeleteStatus: FETCH_STATUS.NULL,
  ataACSFirmwarePatchStatus: FETCH_STATUS.NULL,

  // LOGS
  acsATALogs: null,
  acsATALogsFetchStatus: FETCH_STATUS.NULL,
  acsATALogsRouteParams: initialACSATALogsRouteParams,

  // FAXES
  acsATAFaxes: null,
  acsATAFaxesFetchStatus: FETCH_STATUS.NULL,
  acsATAFaxesRouteParams: initialACSATAFaxesRouteParams,

  // ATAs LIST
  atasList: null,
  atasListFetchStatus: FETCH_STATUS.NULL,
  atasRouteParams: initialATAsListRouteParams,

  // ATA DETAILS
  gsATADetails: null,
  acsATADetails: null,
  ataDetailsFetchStatus: FETCH_STATUS.NULL,
  ataDetailsPostStatus: FETCH_STATUS.NULL,
  atasPostStatus: FETCH_STATUS.NULL,
  ataDetailsPatchStatus: FETCH_STATUS.NULL,
  ataDetailsDeleteStatus: FETCH_STATUS.NULL,
  ataConfigFetchStatus: FETCH_STATUS.NULL,
  ataStatusPostStatus: FETCH_STATUS.NULL,
  atasBulk: null,

  // ACS SETTINGS
  ataACSAdvancedPortSettings: null,
  ataACSAdvancedPortSettingsFetchStatus: FETCH_STATUS.NULL,
  ataACSAdvancedPortSettingsPatchStatus: FETCH_STATUS.NULL,
  ataACSAdvancedSettings: null,
  ataACSAdvancedSettingsFetchStatus: FETCH_STATUS.NULL,
  ataACSAdvancedSettingsPatchStatus: FETCH_STATUS.NULL,

  // ATA BASE TEMPLATE
  ataBaseTemplatesList: null,
  ataBaseTemplatesListFetchStatus: FETCH_STATUS.NULL,
  ataBaseTemplateDetails: null,
  ataBaseTemplatesRouteParams: initialATABaseTemplatesRouteParams,
  ataBaseTemplateDetailsFetchStatus: FETCH_STATUS.NULL,
  ataBaseTemplateDetailsPostStatus: FETCH_STATUS.NULL,
  ataBaseTemplateDetailsDeleteStatus: FETCH_STATUS.NULL,
  ataBaseTemplateDetailsPatchStatus: FETCH_STATUS.NULL,
  gsBaseConfigDetailsPatchStatus: FETCH_STATUS.NULL,

  // ATA ORG TEMPLATE
  ataOrgTemplatesRouteParams: initialATAOrgTemplatesRouteParams,
  ataOrgTemplatesList: null,
  ataOrgTemplatesListFetchStatus: FETCH_STATUS.NULL,
  ataOrgTemplateDetails: null,
  ataOrgTemplateDetailsFetchStatus: FETCH_STATUS.NULL,
  ataOrgTemplateDetailsDeleteStatus: FETCH_STATUS.NULL,
  ataOrgTemplateDetailsPostStatus: FETCH_STATUS.NULL,
  ataOrgTemplateDetailsPatchStatus: FETCH_STATUS.NULL,
  ataOrgTemplateOverridesDetailsPatchStatus: FETCH_STATUS.NULL,

  // ATA TYPES
  ataTypesRouteParams: initialATATypesRouteParams,
  ataTypesList: null,
  ataTypesListFetchStatus: FETCH_STATUS.NULL,
  ataTypeDetails: null,
  ataTypeDetailsFetchStatus: FETCH_STATUS.NULL,
  ataTypeDetailsDeleteStatus: FETCH_STATUS.NULL,
  ataTypeDetailsPostStatus: FETCH_STATUS.NULL,
  ataTypeDetailsPatchStatus: FETCH_STATUS.NULL,

  // ATA ACTIONS
  ataRestunPostStatus: FETCH_STATUS.NULL,
  ataRestun: null,
  ataFactoryResetPostStatus: FETCH_STATUS.NULL,
  ataFactoryReset: null,
  ataRebootPostStatus: FETCH_STATUS.NULL,
  ataReboot: null,
  ataLogFetchStatus: FETCH_STATUS.NULL,
  ataLog: null,
};

export const acsSlice = createSlice({
  name: 'acs',
  initialState: INIT_STATE,
  reducers: {
    setAcsAtaProfilesList: (state, action) => {
      state.acsAtaProfilesList = action.payload;
    },
    setAcsAtaProfilesListFetchStatus: (state, action) => {
      state.acsAtaProfilesListFetchStatus = action.payload;
    },
    setAcsAtaProfileDetails: (state, action) => {
      state.acsAtaProfileDetails = action.payload;
    },
    setAcsAtaProfileDetailsFetchStatus: (state, action) => {
      state.acsAtaProfileDetailsFetchStatus = action.payload;
    },
    setAcsAtaProfileDetailsPatchStatus: (state, action) => {
      state.acsAtaProfileDetailsPatchStatus = action.payload;
    },

    // ACS FIRMWARE
    setACSFirmwarePostStatus: (state, action) => {
      state.acsFirmwarePostStatus = action.payload;
    },
    setACSFirmwarePatchStatus: (state, action) => {
      state.acsFirmwarePatchStatus = action.payload;
    },
    setATAACSFirmwarePatchStatus: (state, action) => {
      state.ataACSFirmwarePatchStatus = action.payload;
    },
    setACSFirmwareDeleteStatus: (state, action) => {
      state.acsFirmwareDeleteStatus = action.payload;
    },
    // LOGS
    setACSATALogs: (state, action) => {
      state.acsATALogs = action.payload;
    },
    setACSATALogsFetchStatus: (state, action) => {
      state.acsATALogsFetchStatus = action.payload;
    },

    // FAXES
    setACSATAFaxes: (state, action) => {
      state.acsATAFaxes = action.payload;
    },
    setACSATAFaxesFetchStatus: (state, action) => {
      state.acsATAFaxesFetchStatus = action.payload;
    },

    // ATAs LIST
    setATAsList: (state, action) => {
      state.atasList = action.payload;
    },
    setATAsListFetchStatus: (state, action) => {
      state.atasListFetchStatus = action.payload;
    },

    // ATA DETAILS
    setGSATADetails: (state, action) => {
      state.gsATADetails = action.payload;
    },
    setACSATADetails: (state, action) => {
      state.acsATADetails = action.payload;
    },
    setATADetailsFetchStatus: (state, action) => {
      state.ataDetailsFetchStatus = action.payload;
    },
    setATADetailsPostStatus: (state, action) => {
      state.ataDetailsPostStatus = action.payload;
    },
    setATADetailsPatchStatus: (state, action) => {
      state.ataDetailsPatchStatus = action.payload;
    },
    setATADetailsDeleteStatus: (state, action) => {
      state.ataDetailsDeleteStatus = action.payload;
    },
    setATAConfigFetchStatus: (state, action) => {
      state.ataConfigFetchStatus = action.payload;
    },
    setATAsPostStatus: (state, action) => {
      state.atasPostStatus = action.payload;
    },

    // ACS SETTINGS
    setATAACSAdvancedPortSettings: (state, action) => {
      state.ataACSAdvancedPortSettings = action.payload;
    },
    setATAACSAdvancedPortSettingsFetchStatus: (state, action) => {
      state.ataACSAdvancedPortSettingsFetchStatus = action.payload;
    },
    setATAACSAdvancedPortSettingsPatchStatus: (state, action) => {
      state.ataACSAdvancedPortSettingsPatchStatus = action.payload;
    },
    setATAACSAdvancedSettings: (state, action) => {
      state.ataACSAdvancedSettings = action.payload;
    },
    setATAACSAdvancedSettingsFetchStatus: (state, action) => {
      state.ataACSAdvancedSettingsFetchStatus = action.payload;
    },
    setATAACSAdvancedSettingsPatchStatus: (state, action) => {
      state.ataACSAdvancedSettingsPatchStatus = action.payload;
    },
    setATAsBulk: (state, action) => {
      state.atasBulk = action.payload;
    },

    // ATA BASE TEMPLATE
    setATABaseTemplatesList: (state, action) => {
      state.ataBaseTemplatesList = action.payload;
    },
    setATABaseTemplatesListFetchStatus: (state, action) => {
      state.ataBaseTemplatesListFetchStatus = action.payload;
    },
    setATABaseTemplateDetails: (state, action) => {
      state.ataBaseTemplateDetails = action.payload;
    },
    setATABaseTemplateDetailsFetchStatus: (state, action) => {
      state.ataBaseTemplateDetailsFetchStatus = action.payload;
    },
    setATABaseTemplateDetailsPostStatus: (state, action) => {
      state.ataBaseTemplateDetailsPostStatus = action.payload;
    },
    setATABaseTemplateDetailsPatchStatus: (state, action) => {
      state.ataBaseTemplateDetailsPatchStatus = action.payload;
    },
    setATABaseTemplateDetailsDeleteStatus: (state, action) => {
      state.ataBaseTemplateDetailsDeleteStatus = action.payload;
    },
    setGSBaseConfigDetailsPatchStatus: (state, action) => {
      state.gsBaseConfigDetailsPatchStatus = action.payload;
    },

    // ATA ORG TEMPLATE
    setATAOrgTemplatesList: (state, action) => {
      state.ataOrgTemplatesList = action.payload;
    },
    setATAOrgTemplatesListFetchStatus: (state, action) => {
      state.ataOrgTemplatesListFetchStatus = action.payload;
    },
    setATAOrgTemplateDetails: (state, action) => {
      state.ataOrgTemplateDetails = action.payload;
    },
    setATAOrgTemplateDetailsFetchStatus: (state, action) => {
      state.ataOrgTemplateDetailsFetchStatus = action.payload;
    },
    setATAOrgTemplateDetailsPostStatus: (state, action) => {
      state.ataOrgTemplateDetailsPostStatus = action.payload;
    },
    setATAOrgTemplateDetailsPatchStatus: (state, action) => {
      state.ataOrgTemplateDetailsPatchStatus = action.payload;
    },
    setATAOrgTemplateDetailsDeleteStatus: (state, action) => {
      state.ataOrgTemplateDetailsDeleteStatus = action.payload;
    },
    setATAOrgTemplateOverridesDetailsPatchStatus: (state, action) => {
      state.ataOrgTemplateOverridesDetailsPatchStatus = action.payload;
    },

    // ATA ORG TEMPLATE
    setATATypesList: (state, action) => {
      state.ataTypesList = action.payload;
    },
    setATATypesListFetchStatus: (state, action) => {
      state.ataTypesListFetchStatus = action.payload;
    },
    setATATypeDetails: (state, action) => {
      state.ataTypeDetails = action.payload;
    },
    setATATypeDetailsFetchStatus: (state, action) => {
      state.ataTypeDetailsFetchStatus = action.payload;
    },
    setATATypeDetailsPostStatus: (state, action) => {
      state.ataTypeDetailsPostStatus = action.payload;
    },
    setATATypeDetailsPatchStatus: (state, action) => {
      state.ataTypeDetailsPatchStatus = action.payload;
    },
    setATATypeDetailsDeleteStatus: (state, action) => {
      state.ataTypeDetailsDeleteStatus = action.payload;
    },

    // ATA ACTIONS
    setATAFactoryResetPostStatus: (state, action) => {
      state.ataFactoryResetPostStatus = action.payload;
    },
    setATARebootPostStatus: (state, action) => {
      state.ataRebootPostStatus = action.payload;
    },
    setATARestunPostStatus: (state, action) => {
      state.ataRestunPostStatus = action.payload;
    },
    setATAFactoryReset: (state, action) => {
      state.ataFactoryReset = action.payload;
    },
    setATAReboot: (state, action) => {
      state.ataReboot = action.payload;
    },
    setATARestun: (state, action) => {
      state.ataRestun = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'acs-ata-profiles') {
        state.acsAtaProfilesRouteParams = { ...state.acsAtaProfilesRouteParams, ...payload.data };
      }

      if (payload.reducer === 'acs-ata-logs') {
        state.acsATALogsRouteParams = { ...state.acsATALogsRouteParams, ...payload.data };
      }

      if (payload.reducer === 'acs-ata-faxes') {
        state.acsATAFaxesRouteParams = { ...state.acsATAFaxesRouteParams, ...payload.data };
      }

      if (payload.reducer === 'ata-list') {
        state.atasRouteParams = { ...state.atasRouteParams, ...payload.data };
      }

      if (payload.reducer === 'ata-base-template') {
        state.ataBaseTemplatesRouteParams = { ...state.ataBaseTemplatesRouteParams, ...payload.data };
      }

      if (payload.reducer === 'ata-org-template') {
        state.ataOrgTemplatesRouteParams = { ...state.ataOrgTemplatesRouteParams, ...payload.data };
      }

      if (payload.reducer === 'ata-types') {
        state.ataTypesRouteParams = { ...state.ataTypesRouteParams, ...payload.data };
      }
    });

    // GET ACS ATA PROFILES LIST
    builder.addCase(getAcsAtaProfilesList.pending, state => {
      state.acsAtaProfilesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getAcsAtaProfilesList.rejected, (state, action) => {
      state.acsAtaProfilesListFetchStatus = FETCH_STATUS.REJECTED;
      state.acsAtaProfilesList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getAcsAtaProfilesList.fulfilled, (state, action) => {
      state.acsAtaProfilesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.acsAtaProfilesList = action.payload;
    });

    // GET ACS ATA PROFILE DETAILS
    builder.addCase(getAcsAtaProfileDetails.pending, state => {
      state.acsAtaProfileDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getAcsAtaProfileDetails.rejected, (state, action) => {
      state.acsAtaProfileDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.acsAtaProfileDetails = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getAcsAtaProfileDetails.fulfilled, (state, action) => {
      state.acsAtaProfileDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.acsAtaProfileDetails = action.payload;
    });

    // PATCH ACS ATA PROFILE DETAILS
    builder.addCase(patchAcsAtaProfileDetails.pending, state => {
      state.acsAtaProfileDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchAcsAtaProfileDetails.rejected, (state, action) => {
      state.acsAtaProfileDetailsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchAcsAtaProfileDetails.fulfilled, (state, action) => {
      state.acsAtaProfileDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      state.acsAtaProfileDetails = action.payload;
      snackbarUtils.success('ACS ATA Profile updated successfully');
    });

    builder.addCase(postACSFirmware.pending, state => {
      state.acsFirmwarePostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postACSFirmware.fulfilled, state => {
      state.acsFirmwarePostStatus = FETCH_STATUS.FULFILLED;
    });
    builder.addCase(postACSFirmware.rejected, (state, action) => {
      state.acsFirmwarePostStatus = FETCH_STATUS.REJECTED;
      if (action.payload) setTimeout(() => snackbarUtils.error(action.payload as string));
    });

    builder.addCase(patchACSFirmware.pending, state => {
      state.acsFirmwarePatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchACSFirmware.fulfilled, state => {
      state.acsFirmwarePatchStatus = FETCH_STATUS.FULFILLED;
    });
    builder.addCase(patchACSFirmware.rejected, (state, action) => {
      state.acsFirmwarePatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        setTimeout(() => snackbarUtils.error(action.payload as string));
      });
    });

    builder.addCase(patchATAACSFirmware.pending, state => {
      state.ataACSFirmwarePatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchATAACSFirmware.fulfilled, state => {
      state.ataACSFirmwarePatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Firmware started patching. It may take a 5 minutes to complete.');
      });
    });
    builder.addCase(patchATAACSFirmware.rejected, (state, action) => {
      state.ataACSFirmwarePatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        setTimeout(() => snackbarUtils.error(action.payload as string));
      });
    });

    builder.addCase(deleteATAACSFirmware.pending, state => {
      state.acsFirmwareDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteATAACSFirmware.fulfilled, state => {
      state.acsFirmwareDeleteStatus = FETCH_STATUS.FULFILLED;
    });
    builder.addCase(deleteATAACSFirmware.rejected, (state, action) => {
      state.acsFirmwareDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        setTimeout(() => snackbarUtils.error(action.payload as string));
      });
    });

    // LOGS
    builder.addCase(getACSATALogs.pending, state => {
      state.acsATALogsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getACSATALogs.fulfilled, (state, action) => {
      state.acsATALogsFetchStatus = FETCH_STATUS.FULFILLED;
      state.acsATALogs = action.payload;
    });
    builder.addCase(getACSATALogs.rejected, (state, action) => {
      state.acsATALogsFetchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        setTimeout(() => snackbarUtils.error(action.payload as string));
      });
    });

    // FAXES
    builder.addCase(getACSATAFaxes.pending, state => {
      state.acsATAFaxesFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getACSATAFaxes.fulfilled, (state, action) => {
      state.acsATAFaxesFetchStatus = FETCH_STATUS.FULFILLED;
      state.acsATAFaxes = action.payload;
    });
    builder.addCase(getACSATAFaxes.rejected, (state, action) => {
      state.acsATAFaxesFetchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        setTimeout(() => snackbarUtils.error(action.payload as string));
      });
    });

    // ATAs LIST
    builder.addCase(getATAsList.pending, state => {
      state.atasListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getATAsList.rejected, (state, action) => {
      state.atasListFetchStatus = FETCH_STATUS.REJECTED;
      state.atasList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getATAsList.fulfilled, (state, action) => {
      state.atasListFetchStatus = FETCH_STATUS.FULFILLED;
      state.atasList = action.payload;
    });

    // GET ATA DETAILS
    builder.addCase(getATADetails.pending, state => {
      state.ataDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getATADetails.rejected, (state, action) => {
      state.ataDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.acsATADetails = null;
      state.gsATADetails = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getATADetails.fulfilled, (state, action) => {
      state.ataDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      if (action.payload.type === 'ACS') {
        state.acsATADetails = action.payload.details as ACSATAType;
      }

      if (action.payload.type === 'GS') {
        state.gsATADetails = action.payload.details as GrandstreamATAType;
      }
    });

    // DELETE ATA DETAILS
    builder.addCase(deleteATADetails.pending, state => {
      state.ataDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteATADetails.rejected, (state, action) => {
      state.ataDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(deleteATADetails.fulfilled, (state, action) => {
      if (action.payload.fetchList) {
        state.ataDetailsDeleteStatus = FETCH_STATUS.NULL;
      } else {
        state.ataDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      }
      setTimeout(() => {
        snackbarUtils.success('ATA has been removed!');
      });
    });

    // PATCH ATA DETAILS
    builder.addCase(patchATADetails.pending, state => {
      state.ataDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchATADetails.rejected, (state, action) => {
      state.ataDetailsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchATADetails.fulfilled, state => {
      state.ataDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('ATA has been changed!');
      });
    });

    // POST ATA DETAILS
    builder.addCase(postATADetails.pending, state => {
      state.ataDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postATADetails.rejected, (state, action) => {
      state.ataDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postATADetails.fulfilled, state => {
      state.ataDetailsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('ATA has been added!');
      });
    });

    // FACTORY RESET ATA
    builder.addCase(factoryResetATA.pending, state => {
      state.ataFactoryResetPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(factoryResetATA.rejected, (state, action) => {
      state.ataFactoryResetPostStatus = FETCH_STATUS.REJECTED;
      state.ataFactoryReset = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(factoryResetATA.fulfilled, (state, action) => {
      state.ataFactoryResetPostStatus = FETCH_STATUS.FULFILLED;
      state.ataFactoryReset = action.payload;
      setTimeout(() => {
        snackbarUtils.success('ATA reset to factory settings has been started!');
      });
    });

    // FACTORY REBOOT ATA
    builder.addCase(rebootATA.pending, state => {
      state.ataRebootPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(rebootATA.rejected, (state, action) => {
      state.ataRebootPostStatus = FETCH_STATUS.REJECTED;
      state.ataReboot = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(rebootATA.fulfilled, (state, action) => {
      state.ataRebootPostStatus = FETCH_STATUS.FULFILLED;
      state.ataReboot = action.payload;
      setTimeout(() => {
        snackbarUtils.success('ATA reboot started!');
      });
    });

    // UPDATE ATA STATUS
    builder.addCase(updateATAStatus.pending, state => {
      state.ataStatusPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(updateATAStatus.rejected, (state, action) => {
      state.ataStatusPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(updateATAStatus.fulfilled, state => {
      state.ataStatusPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('ATA status update started. Come back later, this may take a few minutes');
      });
    });

    // GET ATA CONFIG FILE
    builder.addCase(getATAConfigFile.pending, state => {
      state.ataConfigFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getATAConfigFile.rejected, (state, action) => {
      state.ataConfigFetchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getATAConfigFile.fulfilled, state => {
      state.ataConfigFetchStatus = FETCH_STATUS.FULFILLED;
    });

    // ACS SETTINGS
    builder.addCase(getATAACSAdvancedPortSettings.pending, state => {
      state.ataACSAdvancedPortSettingsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getATAACSAdvancedPortSettings.rejected, (state, action) => {
      state.ataACSAdvancedPortSettingsFetchStatus = FETCH_STATUS.REJECTED;
      state.ataACSAdvancedPortSettings = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getATAACSAdvancedPortSettings.fulfilled, (state, action) => {
      state.ataACSAdvancedPortSettingsFetchStatus = FETCH_STATUS.FULFILLED;
      state.ataACSAdvancedPortSettings = action.payload;
    });

    // ACS SETTINGS
    builder.addCase(getATAACSAdvancedSettings.pending, state => {
      state.ataACSAdvancedSettingsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getATAACSAdvancedSettings.rejected, (state, action) => {
      state.ataACSAdvancedSettingsFetchStatus = FETCH_STATUS.REJECTED;
      state.ataACSAdvancedSettings = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getATAACSAdvancedSettings.fulfilled, (state, action) => {
      state.ataACSAdvancedSettingsFetchStatus = FETCH_STATUS.FULFILLED;
      state.ataACSAdvancedSettings = action.payload;
    });

    // RESET ATA ACS ADVANCED PORT SETTINGS
    builder.addCase(resetATAACSAdvancedPortSettings.pending, state => {
      state.ataACSAdvancedPortSettingsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(resetATAACSAdvancedPortSettings.rejected, (state, action) => {
      state.ataACSAdvancedPortSettingsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(resetATAACSAdvancedPortSettings.fulfilled, state => {
      state.ataACSAdvancedPortSettingsPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('ACS ATA Advanced Settings has been reset!');
      });
    });

    // PATCH ATA ACS ADVANCED PORT SETTINGS
    builder.addCase(patchATAACSAdvancedPortSettings.pending, state => {
      state.ataACSAdvancedPortSettingsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchATAACSAdvancedPortSettings.rejected, (state, action) => {
      state.ataACSAdvancedPortSettingsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchATAACSAdvancedPortSettings.fulfilled, state => {
      state.ataACSAdvancedPortSettingsPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('ACS ATA Advanced Port Settings has been updated!');
      });
    });

    // POST ATAs
    builder.addCase(postATAs.pending, state => {
      state.atasPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postATAs.rejected, (state, action) => {
      state.atasPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postATAs.fulfilled, state => {
      state.atasPostStatus = FETCH_STATUS.FULFILLED;
    });

    // UPDATE ALL GS ATAs Configs
    builder.addCase(updateAllGSATAsConfigs.pending, () => {
      // state.ataACSAdvancedPortSettingsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(updateAllGSATAsConfigs.rejected, (_, action) => {
      // state.ataACSAdvancedPortSettingsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(updateAllGSATAsConfigs.fulfilled, () => {
      // state.ataACSAdvancedPortSettingsPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success(
          'Update all GS ATA configs has been started. Come back later, this may take a few minutes.'
        );
      });
    });

    // ATA BASE TEMPLATES LIST
    builder.addCase(getATABaseTemplateList.pending, state => {
      state.ataBaseTemplatesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getATABaseTemplateList.rejected, (state, action) => {
      state.ataBaseTemplatesListFetchStatus = FETCH_STATUS.REJECTED;
      state.ataBaseTemplatesList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getATABaseTemplateList.fulfilled, (state, action) => {
      state.ataBaseTemplatesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.ataBaseTemplatesList = action.payload;
    });

    // ATA BASE TEMPLATES DETAILS
    builder.addCase(getATABaseTemplateDetails.pending, state => {
      state.ataBaseTemplateDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getATABaseTemplateDetails.rejected, (state, action) => {
      state.ataBaseTemplateDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.ataBaseTemplateDetails = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getATABaseTemplateDetails.fulfilled, (state, action) => {
      state.ataBaseTemplateDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.ataBaseTemplateDetails = action.payload;
    });

    // DELETE ATA BASE TEMPLATE
    builder.addCase(deleteATABaseTemplateDetails.pending, state => {
      state.ataBaseTemplateDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteATABaseTemplateDetails.rejected, (state, action) => {
      state.ataBaseTemplateDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(deleteATABaseTemplateDetails.fulfilled, (state, action) => {
      if (action.payload.fetchList) {
        state.ataBaseTemplateDetailsDeleteStatus = FETCH_STATUS.NULL;
      } else {
        state.ataBaseTemplateDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      }
      setTimeout(() => {
        snackbarUtils.success('ATA Base Template has been removed!');
      });
    });

    // PATCH ATA BASE TEMPLATE
    builder.addCase(patchATABaseTemplateDetails.pending, state => {
      state.ataBaseTemplateDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchATABaseTemplateDetails.rejected, (state, action) => {
      state.ataBaseTemplateDetailsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchATABaseTemplateDetails.fulfilled, (state, action) => {
      if (action.payload.disableRedirect) {
        state.ataBaseTemplateDetailsPatchStatus = FETCH_STATUS.NULL;
      } else {
        state.ataBaseTemplateDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      }
      setTimeout(() => {
        snackbarUtils.success('ATA Base Template has been changed!');
      });
    });

    // POST ATA BASE TEMPLATE
    builder.addCase(postATABaseTemplateDetails.pending, state => {
      state.ataBaseTemplateDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postATABaseTemplateDetails.rejected, (state, action) => {
      state.ataBaseTemplateDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postATABaseTemplateDetails.fulfilled, state => {
      state.ataBaseTemplateDetailsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('ATA Base Template has been added!');
      });
    });

    // PATCH GS ATA BASE TEMPLATE
    builder.addCase(patchGSBaseConfigDetails.pending, state => {
      state.gsBaseConfigDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchGSBaseConfigDetails.rejected, (state, action) => {
      state.gsBaseConfigDetailsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchGSBaseConfigDetails.fulfilled, state => {
      state.gsBaseConfigDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('GS Base Config has been changed!');
      });
    });

    // ATA ORG TEMPLATES LIST
    builder.addCase(getATAOrgTemplateList.pending, state => {
      state.ataOrgTemplatesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getATAOrgTemplateList.rejected, (state, action) => {
      state.ataOrgTemplatesListFetchStatus = FETCH_STATUS.REJECTED;
      state.ataOrgTemplatesList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getATAOrgTemplateList.fulfilled, (state, action) => {
      state.ataOrgTemplatesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.ataOrgTemplatesList = action.payload;
    });

    // ATA ORG TEMPLATES DETAILS
    builder.addCase(getATAOrgTemplateDetails.pending, state => {
      state.ataOrgTemplateDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getATAOrgTemplateDetails.rejected, (state, action) => {
      state.ataOrgTemplateDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.ataOrgTemplateDetails = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getATAOrgTemplateDetails.fulfilled, (state, action) => {
      state.ataOrgTemplateDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.ataOrgTemplateDetails = action.payload;
    });

    // DELETE ATA ORG TEMPLATE
    builder.addCase(deleteATAOrgTemplateDetails.pending, state => {
      state.ataOrgTemplateDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteATAOrgTemplateDetails.rejected, (state, action) => {
      state.ataOrgTemplateDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(deleteATAOrgTemplateDetails.fulfilled, (state, action) => {
      if (action.payload.fetchList) {
        state.ataOrgTemplateDetailsDeleteStatus = FETCH_STATUS.NULL;
      } else {
        state.ataOrgTemplateDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      }
      setTimeout(() => {
        snackbarUtils.success('ATA Org Template has been removed!');
      });
    });

    // PATCH ATA ORG TEMPLATE
    builder.addCase(patchATAOrgTemplateDetails.pending, state => {
      state.ataOrgTemplateDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchATAOrgTemplateDetails.rejected, (state, action) => {
      state.ataOrgTemplateDetailsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchATAOrgTemplateDetails.fulfilled, (state, action) => {
      if (action.payload.disableRedirect) {
        state.ataOrgTemplateDetailsPatchStatus = FETCH_STATUS.NULL;
      } else {
        state.ataOrgTemplateDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      }
      setTimeout(() => {
        snackbarUtils.success('ATA Org Template has been changed!');
      });
    });

    // POST ATA ORG TEMPLATE
    builder.addCase(postATAOrgTemplateDetails.pending, state => {
      state.ataOrgTemplateDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postATAOrgTemplateDetails.rejected, (state, action) => {
      state.ataOrgTemplateDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postATAOrgTemplateDetails.fulfilled, state => {
      state.ataOrgTemplateDetailsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('ATA Org Template has been added!');
      });
    });

    // PATCH GS ATA ORG TEMPLATE
    builder.addCase(patchATAOrgTemplateOverridesDetails.pending, state => {
      state.ataOrgTemplateOverridesDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchATAOrgTemplateOverridesDetails.rejected, (state, action) => {
      state.ataOrgTemplateOverridesDetailsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchATAOrgTemplateOverridesDetails.fulfilled, state => {
      state.ataOrgTemplateOverridesDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('ATA Org Template overrides has been saved!');
      });
    });

    // ATA TYPES LIST
    builder.addCase(getATATypesList.pending, state => {
      state.ataTypesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getATATypesList.rejected, (state, action) => {
      state.ataTypesListFetchStatus = FETCH_STATUS.REJECTED;
      state.ataTypesList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getATATypesList.fulfilled, (state, action) => {
      state.ataTypesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.ataTypesList = action.payload;
    });

    // ATA TYPES DETAILS
    builder.addCase(getATATypeDetails.pending, state => {
      state.ataTypeDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getATATypeDetails.rejected, (state, action) => {
      state.ataTypeDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.ataTypeDetails = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getATATypeDetails.fulfilled, (state, action) => {
      state.ataTypeDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.ataTypeDetails = action.payload;
    });

    // DELETE ATA TYPES
    builder.addCase(deleteATATypeDetails.pending, state => {
      state.ataTypeDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteATATypeDetails.rejected, (state, action) => {
      state.ataTypeDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(deleteATATypeDetails.fulfilled, (state, action) => {
      if (action.payload.fetchList) {
        state.ataTypeDetailsDeleteStatus = FETCH_STATUS.NULL;
      } else {
        state.ataTypeDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      }
      setTimeout(() => {
        snackbarUtils.success('ATA Type has been removed!');
      });
    });

    // PATCH ATA TYPES
    builder.addCase(patchATATypeDetails.pending, state => {
      state.ataTypeDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchATATypeDetails.rejected, (state, action) => {
      state.ataTypeDetailsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchATATypeDetails.fulfilled, (state, action) => {
      if (action.payload.disableRedirect) {
        state.ataTypeDetailsPatchStatus = FETCH_STATUS.NULL;
      } else {
        state.ataTypeDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      }
      setTimeout(() => {
        snackbarUtils.success('ATA Type has been changed!');
      });
    });

    // POST ATA TYPES
    builder.addCase(postATATypeDetails.pending, state => {
      state.ataTypeDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postATATypeDetails.rejected, (state, action) => {
      state.ataTypeDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postATATypeDetails.fulfilled, state => {
      state.ataTypeDetailsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('ATA Type has been added!');
      });
    });
    // Restun Actions
    builder.addCase(restunATA.pending, state => {
      state.ataRestunPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(restunATA.fulfilled, (state, action) => {
      state.ataRestunPostStatus = FETCH_STATUS.FULFILLED;
      state.ataRestun = action.payload;
      snackbarUtils.success('The STUN procedure has started!');
    });
    builder.addCase(restunATA.rejected, (state, action) => {
      state.ataRestunPostStatus = FETCH_STATUS.REJECTED;
      state.ataRestun = null;
      snackbarUtils.error((action.payload as string) || 'Error during ATA restun operation!');
    });

    // GET ACS ATA LOG DETAILS
    builder.addCase(getACSATALogDetails.pending, state => {
      state.ataLogFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getACSATALogDetails.fulfilled, (state, action) => {
      state.ataLogFetchStatus = FETCH_STATUS.FULFILLED;
      state.ataLog = action.payload;
    });
    builder.addCase(getACSATALogDetails.rejected, (state, action) => {
      state.ataLogFetchStatus = FETCH_STATUS.REJECTED;
      state.ataLog = null;
      snackbarUtils.error(action.payload as string);
    });

    // GET ACS ATA LOG LATEST DETAILS
    builder.addCase(getACSATALogLatestDetails.pending, state => {
      state.ataLogFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getACSATALogLatestDetails.fulfilled, (state, action) => {
      state.ataLogFetchStatus = FETCH_STATUS.FULFILLED;
      state.ataLog = action.payload;
    });
    builder.addCase(getACSATALogLatestDetails.rejected, (state, action) => {
      state.ataLogFetchStatus = FETCH_STATUS.REJECTED;
      state.ataLog = null;
      snackbarUtils.error(action.payload as string);
    });
  },
});

export const {
  setAcsAtaProfilesList,
  setAcsAtaProfilesListFetchStatus,
  setAcsAtaProfileDetails,
  setAcsAtaProfileDetailsFetchStatus,
  setAcsAtaProfileDetailsPatchStatus,
  setACSFirmwarePostStatus,
  setACSFirmwarePatchStatus,
  setACSFirmwareDeleteStatus,
  setATAACSFirmwarePatchStatus,
  setACSATALogsFetchStatus,
  setACSATALogs,
  setACSATAFaxesFetchStatus,
  setACSATAFaxes,
  setATAsList,
  setATAsListFetchStatus,
  setGSATADetails,
  setACSATADetails,
  setATADetailsDeleteStatus,
  setATADetailsPostStatus,
  setATAsPostStatus,
  setATADetailsPatchStatus,
  setATADetailsFetchStatus,
  setATARebootPostStatus,
  setATAFactoryResetPostStatus,
  setATAConfigFetchStatus,
  setATAACSAdvancedPortSettings,
  setATAACSAdvancedPortSettingsFetchStatus,
  setATAACSAdvancedPortSettingsPatchStatus,
  setATAACSAdvancedSettingsPatchStatus,
  setATAACSAdvancedSettings,
  setATAACSAdvancedSettingsFetchStatus,
  setATAsBulk,
  setATABaseTemplatesListFetchStatus,
  setATABaseTemplatesList,
  setATABaseTemplateDetailsFetchStatus,
  setATABaseTemplateDetails,
  setATABaseTemplateDetailsPatchStatus,
  setATABaseTemplateDetailsPostStatus,
  setGSBaseConfigDetailsPatchStatus,
  setATABaseTemplateDetailsDeleteStatus,
  setATAOrgTemplateOverridesDetailsPatchStatus,
  setATAOrgTemplatesList,
  setATAOrgTemplateDetailsPostStatus,
  setATAOrgTemplateDetailsDeleteStatus,
  setATAOrgTemplateDetailsFetchStatus,
  setATAOrgTemplateDetailsPatchStatus,
  setATAOrgTemplatesListFetchStatus,
  setATAOrgTemplateDetails,
  setATATypeDetailsDeleteStatus,
  setATATypesListFetchStatus,
  setATATypeDetailsPatchStatus,
  setATATypeDetailsPostStatus,
  setATATypesList,
  setATATypeDetailsFetchStatus,
  setATATypeDetails,
  setATARestunPostStatus,
  setATAReboot,
  setATARestun,
  setATAFactoryReset,
} = acsSlice.actions;

export default acsSlice.reducer;
