import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../redux/Store';
import axios from 'axios';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { additionalQueryParams } from '../../utils/utilities';
import { setModalContent, setShowModal } from '../utils/utils.slice';
import { PortRequestType } from '../../types/port-request/port-request.type';

export const postPortingDetails = createAsyncThunk<PortRequestType, { data: unknown }, { state: RootState }>(
  'portings/postPortingDetails',
  async ({ data }, thunkAPI) => {
    try {
      const response = await axios.post<PortRequestType>(`${process.env.REACT_APP_API_URL}/port-request`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating port request!');
    }
  }
);

export const getPortingsList = createAsyncThunk<PaginationType<PortRequestType>, RouteParamsType, { state: RootState }>(
  'portings/getPortingsList',
  async (params, thunkAPI) => {
    try {
      const additionalParams = additionalQueryParams(
        thunkAPI.getState().sharedReducer.selectedCompany,
        thunkAPI.getState().sharedReducer.selectedLocation,
        thunkAPI.getState().sharedReducer.includeSuborgs,
        thunkAPI.getState().sharedReducer.selectedCountry
      );
      const mergedParams = { ...params, ...additionalParams };
      const queryString = Object.keys(mergedParams)
        .map(key => key + '=' + mergedParams[key])
        .join('&');

      const response = await axios.get<PaginationType<PortRequestType>>(
        `${process.env.REACT_APP_API_URL}/port-request${queryString ? `?${queryString}` : ''}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching port requests!');
    }
  }
);

export const getPortingDetails = createAsyncThunk<PortRequestType, { id: number }, { state: RootState }>(
  'portings/getPortingDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<PortRequestType>(`${process.env.REACT_APP_API_URL}/port-request/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching port request!');
    }
  }
);

export const patchPortingDetails = createAsyncThunk<
  PortRequestType,
  { id: number; data: unknown },
  {
    state: RootState;
  }
>('portings/patchPortingDetails', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<PortRequestType>(`${process.env.REACT_APP_API_URL}/port-request/${id}`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating port request!');
  }
});

export const deletePortingDetails = createAsyncThunk<
  PortRequestType,
  { id: number; fetchList: boolean },
  { state: RootState }
>('portings/deletePortingDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<PortRequestType>(`${process.env.REACT_APP_API_URL}/port-request/${id}`);
    if (fetchList) thunkAPI.dispatch(getPortingsList(thunkAPI.getState().portingsReducer.portingsRouteParams));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting port request!');
  }
});

export const cancelPortingDetails = createAsyncThunk<PortRequestType, { id: number }, { state: RootState }>(
  'portings/cancelPortingDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.delete<PortRequestType>(
        `${process.env.REACT_APP_API_URL}/port-request/cancel/${id}`
      );
      thunkAPI.dispatch(getPortingDetails({ id }));
      thunkAPI.dispatch(setShowModal(false));
      thunkAPI.dispatch(setModalContent(null));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during canceling port request!');
    }
  }
);
