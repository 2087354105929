import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaginationType } from '../../types/utils/pagination.type';
import { DIDsListItemType } from '../../types/did/did.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { RootState } from '../../redux/Store';
import axios from 'axios';
import { additionalQueryParams, groupBy, readTree } from '../../utils/utilities';
import { ROLECONSTANTS } from '../utils/utils.action';
import {
  setCachedCountriesByOrganizationID,
  setCachedGroupedCountriesByOrganizationID,
  setFullCountriesList,
  setFullExternalProviderList,
  setFullExternalSystemList,
  setFullGroupedCountriesList,
} from './shared.slice';

export const getCustomerDashboardDIDsList = createAsyncThunk<
  PaginationType<DIDsListItemType>,
  RouteParamsType,
  { state: RootState }
>('shared/getCustomerDashboardDIDsList/no-loader', async (params, thunkAPI) => {
  try {
    const additionalParams = additionalQueryParams(
      thunkAPI.getState().sharedReducer.selectedCompany,
      thunkAPI.getState().sharedReducer.selectedLocation,
      thunkAPI.getState().sharedReducer.includeSuborgs,
      thunkAPI.getState().sharedReducer.selectedCountry
    );
    const mergedParams = { ...params, ...additionalParams };
    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<DIDsListItemType>>(
      `${process.env.REACT_APP_API_URL}/dids${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching DIDs!');
  }
});

export const getDomain = createAsyncThunk<any, any, { state: RootState }>(
  'shared/getDomain',
  async (params, thunkAPI) => {
    try {
      const queryString = Object.keys(params || {})
        .map(key => key + '=' + params[key])
        .join('&');
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/organizations/brand/${queryString ? `?${queryString}` : ''}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting organization details!');
    }
  }
);

export const getJotFormURL = createAsyncThunk<string, void, { state: RootState }>(
  'shared/getJotFormURL',
  async (_, thunkAPI) => {
    try {
      const user = thunkAPI.getState().authReducer.user;
      const selectedCompany = thunkAPI.getState().sharedReducer.selectedCompany;

      const response = await axios.get<string>(
        `${process.env.REACT_APP_API_URL}/organizations/${
          selectedCompany?.id === 'all' ? user?.organization?.id : selectedCompany?.id
        }/jot-form`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting jot form URL!');
    }
  }
);

export const getFullOrganizationList = createAsyncThunk<
  any,
  {
    orgId: number | null | string;
    parentFor?: any;
    routerOrganizations?: boolean;
    strictCondition?: boolean;
  },
  { state: RootState }
>(
  'shared/getFullOrganizationList',
  async ({ routerOrganizations = false, strictCondition = false, orgId = null, parentFor = null }, thunkAPI) => {
    try {
      const user = thunkAPI.getState().authReducer.user;
      const isSuperAdmin = user.role === ROLECONSTANTS.superadmin;
      const params = { limit: 1000, page: 1, order: 'organization.id' };
      if (isSuperAdmin && !strictCondition) {
        params['org_id'] = user.organization.id;
      } else {
        if (orgId === 'all') {
          params['org_id'] = user.organization.id;
        } else {
          if (orgId) params['org_id'] = orgId;
        }
      }
      if (parentFor !== null) {
        params['parent_for'] = parentFor;
      }

      const queryString = Object.keys(params || {})
        .map(key => key + '=' + params[key])
        .join('&');

      let url;
      if (!routerOrganizations) {
        url = `${process.env.REACT_APP_API_URL}/organizations/tree${queryString ? `?${queryString}` : ''}`;
      } else {
        const routerDetails = thunkAPI.getState().routersReducer.routerDetails;
        url = `${process.env.REACT_APP_API_URL}/router/tree?sn=${routerDetails?.serial_number}`;
      }

      const response = await axios.get<any>(url);

      let obj = [];
      if (response.data?.id) {
        obj = readTree(response.data, '', null);
      }

      return obj;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting full organizations list!');
    }
  }
);

export const getFullLocationList = createAsyncThunk<any, any, { state: RootState }>(
  'shared/getFullLocationList',
  async (data = {}, thunkAPI) => {
    try {
      const params = { limit: 1000, page: 1, order: 'id' };
      Object.keys(data).forEach(key => {
        params[key] = data[key];
      });
      const queryString = Object.keys(params || {})
        .map(key => key + '=' + params[key])
        .join('&');

      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_URL}/location${queryString ? `?${queryString}` : ''}`
      );
      return response.data?.items || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting full locations list!');
    }
  }
);

export const getFullRouterList = createAsyncThunk<any, any, { state: RootState }>(
  'shared/getFullRouterList',
  async (data = {}, thunkAPI) => {
    try {
      const params = { limit: 1000, page: 1, order: 'id' };
      Object.keys(data).forEach(key => {
        params[key] = data[key];
      });
      const queryString = Object.keys(params || {})
        .map(key => key + '=' + params[key])
        .join('&');

      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_URL}/routers${queryString ? `?${queryString}` : ''}`
      );
      return response.data?.items || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting full routers list!');
    }
  }
);

export const getFullServiceList = createAsyncThunk<any, { locationId: number }, { state: RootState }>(
  'shared/getFullServiceList',
  async ({ locationId }, thunkAPI) => {
    try {
      const response = await axios.get<any>(`${process.env.REACT_APP_API_URL}/location/${locationId}/services`);
      return response.data?.services || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting full services list!');
    }
  }
);

export const getFullSimsList = createAsyncThunk<any, any, { state: RootState }>(
  'shared/getFullSimsList',
  async (data = {}, thunkAPI) => {
    try {
      const params = { limit: 1000, page: 1, order: 'id' };
      Object.keys(data).forEach(key => {
        params[key] = data[key];
      });
      const queryString = Object.keys(params || {})
        .map(key => key + '=' + params[key])
        .join('&');

      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_URL}/tc-sim${queryString ? `?${queryString}` : ''}`
      );
      return response.data?.items || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting full SIMs list!');
    }
  }
);

export const getFullDataPlanTypeList = createAsyncThunk<any, any, { state: RootState }>(
  'shared/getFullDataPlanTypeList',
  async (data = {}, thunkAPI) => {
    try {
      const params = { limit: 1000, page: 1, order: 'id' };
      Object.keys(data).forEach(key => {
        params[key] = data[key];
      });
      const queryString = Object.keys(params || {})
        .map(key => key + '=' + params[key])
        .join('&');

      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_URL}/data-plans/types${queryString ? `?${queryString}` : ''}`
      );
      return response.data?.items || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting full data plan types list!');
    }
  }
);

export const getFullServiceTypeList = createAsyncThunk<any, any, { state: RootState }>(
  'shared/getFullServiceTypeList',
  async (data = {}, thunkAPI) => {
    try {
      const params = { limit: 1000, page: 1, order: 'id' };
      Object.keys(data).forEach(key => {
        params[key] = data[key];
      });
      const queryString = Object.keys(params || {})
        .map(key => key + '=' + params[key])
        .join('&');

      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_URL}/tc-service-type${queryString ? `?${queryString}` : ''}`
      );
      return response.data?.items || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting full service types list!');
    }
  }
);

export const getFullServiceTypeCategoryList = createAsyncThunk<any, any, { state: RootState }>(
  'shared/getFullServiceTypeCategoryList',
  async (data = {}, thunkAPI) => {
    try {
      const params = { limit: 1000, page: 1, order: 'id' };
      Object.keys(data).forEach(key => {
        params[key] = data[key];
      });
      const queryString = Object.keys(params || {})
        .map(key => key + '=' + params[key])
        .join('&');

      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_URL}/tc-service-type/categories${queryString ? `?${queryString}` : ''}`
      );

      const items: any = [];
      if (response.data && response.data?.length > 0) {
        response.data.forEach(x => {
          items.push({ id: x, name: x });
        });
      }

      return items;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response.data.message || 'Error during getting full service type categories list!'
      );
    }
  }
);

export const getFullEquipmentGroupList = createAsyncThunk<any, any, { state: RootState }>(
  'shared/getFullEquipmentGroupList',
  async (data = {}, thunkAPI) => {
    try {
      const params = { limit: 1000, page: 1, order: 'id' };
      Object.keys(data).forEach(key => {
        params[key] = data[key];
      });
      const queryString = Object.keys(params || {})
        .map(key => key + '=' + params[key])
        .join('&');

      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_URL}/equipment-group${queryString ? `?${queryString}` : ''}`
      );
      return response.data?.items || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response.data.message || 'Error during getting full equipment groups list!'
      );
    }
  }
);

export const getFullPBXList = createAsyncThunk<any, any, { state: RootState }>(
  'shared/getFullPBXList',
  async (data = {}, thunkAPI) => {
    try {
      const params = { limit: 1000, page: 1, order: 'id' };
      Object.keys(data).forEach(key => {
        params[key] = data[key];
      });
      const queryString = Object.keys(params || {})
        .map(key => key + '=' + params[key])
        .join('&');

      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_URL}/pbxs${queryString ? `?${queryString}` : ''}`
      );
      return response.data?.items || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting full PBXs list!');
    }
  }
);

export const getFullATATypeList = createAsyncThunk<any, any, { state: RootState }>(
  'shared/getFullATATypeList',
  async (data = {}, thunkAPI) => {
    try {
      const params = { limit: 1000, page: 1, order: 'id' };
      Object.keys(data).forEach(key => {
        params[key] = data[key];
      });
      const queryString = Object.keys(params || {})
        .map(key => key + '=' + params[key])
        .join('&');

      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_URL}/atas/types${queryString ? `?${queryString}` : ''}`
      );
      return response.data?.items || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting full ATA types list!');
    }
  }
);

export const getFullDialerTypesList = createAsyncThunk<any, any, { state: RootState }>(
  'shared/getFullDialerTypesList',
  async (data = {}, thunkAPI) => {
    try {
      const params = { limit: 1000, page: 1, order: 'id' };
      Object.keys(data).forEach(key => {
        params[key] = data[key];
      });
      const queryString = Object.keys(params || {})
        .map(key => key + '=' + params[key])
        .join('&');

      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_URL}/dialer-type${queryString ? `?${queryString}` : ''}`
      );
      return response.data?.items || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting full dialer types list!');
    }
  }
);

export const getFullATAOrgTemplateList = createAsyncThunk<any, any, { state: RootState }>(
  'shared/getFullATAOrgTemplateList',
  async (data = {}, thunkAPI) => {
    try {
      const params = { limit: 1000, page: 1, order: 'id' };
      Object.keys(data).forEach(key => {
        params[key] = data[key];
      });
      const queryString = Object.keys(params || {})
        .map(key => key + '=' + params[key])
        .join('&');

      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_URL}/ata-org-templates${queryString ? `?${queryString}` : ''}`
      );
      return response.data?.items || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response.data.message || 'Error during getting full ATA org template list!'
      );
    }
  }
);

export const getFullATABaseTemplateList = createAsyncThunk<any, any, { state: RootState }>(
  'shared/getFullATABaseTemplateList',
  async (data = {}, thunkAPI) => {
    try {
      const params = { limit: 1000, page: 1, order: 'id' };
      Object.keys(data).forEach(key => {
        params[key] = data[key];
      });
      const queryString = Object.keys(params || {})
        .map(key => key + '=' + params[key])
        .join('&');

      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_URL}/ata-base-templates${queryString ? `?${queryString}` : ''}`
      );
      return response.data?.items || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response.data.message || 'Error during getting full ATA base template list!'
      );
    }
  }
);

export const getFullAsyncTaskTypesList = createAsyncThunk<any, any, { state: RootState }>(
  'shared/getFullAsyncTaskTypesList',
  async (data = {}, thunkAPI) => {
    try {
      const params = { limit: 1000, page: 1, order: 'id' };
      Object.keys(data).forEach(key => {
        params[key] = data[key];
      });
      const queryString = Object.keys(params || {})
        .map(key => key + '=' + params[key])
        .join('&');

      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_URL}/async-task/types${queryString ? `?${queryString}` : ''}`
      );

      const items: any = [];
      if (response.data && response.data?.length > 0) {
        response.data.forEach(x => {
          items.push({ id: x, name: x });
        });
      }

      return items || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response.data.message || 'Error during getting full async task types list!'
      );
    }
  }
);

export const getFullBucketsList = createAsyncThunk<any, any, { state: RootState }>(
  'shared/getFullBucketsList',
  async (data = {}, thunkAPI) => {
    try {
      const params = { limit: 1000, page: 1, order: 'id' };
      Object.keys(data).forEach(key => {
        params[key] = data[key];
      });
      const queryString = Object.keys(params || {})
        .map(key => key + '=' + params[key])
        .join('&');

      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_URL}/secure-alarm/bucket${queryString ? '?' + queryString : ''}`
      );
      return response.data?.items || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting full buckets list!');
    }
  }
);

export const getFullEnclosureTypesList = createAsyncThunk<any, any, { state: RootState }>(
  'shared/getFullEnclosureTypesList',
  async (data = {}, thunkAPI) => {
    try {
      const params = { limit: 1000, page: 1, order: 'enclosure_type.id' };
      Object.keys(data).forEach(key => {
        params[key] = data[key];
      });
      const queryString = Object.keys(params || {})
        .map(key => key + '=' + params[key])
        .join('&');

      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_URL}/enclosure-types${queryString ? '?' + queryString : ''}`
      );
      return response.data?.items || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting full enclosures list!');
    }
  }
);

export const getFullCountryCategoriesList = createAsyncThunk<any, any, { state: RootState }>(
  'shared/getFullCountryCategoriesList',
  async (data = {}, thunkAPI) => {
    try {
      const params = { limit: 1000, page: 1, order: 'id' };
      Object.keys(data).forEach(key => {
        params[key] = data[key];
      });
      const queryString = Object.keys(params || {})
        .map(key => key + '=' + params[key])
        .join('&');

      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_URL}/countries/categories${queryString ? '?' + queryString : ''}`
      );

      const items: any = [];
      if (response.data && response.data?.length > 0) {
        response.data.forEach(x => {
          items.push({ id: x, name: x });
        });
      }

      return items || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response.data.message || 'Error during getting full country categories list!'
      );
    }
  }
);

export const getFullWarehouseList = createAsyncThunk<any, any, { state: RootState }>(
  'shared/getFullWarehouseList',
  async (data = {}, thunkAPI) => {
    try {
      const params = { limit: 1000, page: 1, order: 'id' };
      Object.keys(data).forEach(key => {
        params[key] = data[key];
      });
      const queryString = Object.keys(params || {})
        .map(key => key + '=' + params[key])
        .join('&');

      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_URL}/warehouse${queryString ? '?' + queryString : ''}`
      );
      return response.data?.items || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting full warehouses list!');
    }
  }
);

export const getFullATAACSProfileList = createAsyncThunk<any, any, { state: RootState }>(
  'shared/getFullATAACSProfileList',
  async (data = {}, thunkAPI) => {
    try {
      const params = { limit: 1000, page: 1, order: 'id' };
      Object.keys(data).forEach(key => {
        params[key] = data[key];
      });
      const queryString = Object.keys(params || {})
        .map(key => key + '=' + params[key])
        .join('&');

      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_URL}/acs/profiles${queryString ? '?' + queryString : ''}`
      );
      return response.data || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response.data.message || 'Error during getting full ATA ACS profiles list!'
      );
    }
  }
);

export const getFullTCBlockList = createAsyncThunk<any, any, { state: RootState }>(
  'shared/getFullTCBlockList',
  async (data = {}, thunkAPI) => {
    try {
      const params = { limit: 1000, page: 1, order: 'id' };
      Object.keys(data).forEach(key => {
        params[key] = data[key];
      });
      const queryString = Object.keys(params || {})
        .map(key => key + '=' + params[key])
        .join('&');

      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_URL}/tc-block${queryString ? '?' + queryString : ''}`
      );
      return response.data?.items || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting full TC Blocks list!');
    }
  }
);

export const getFullLineTypesList = createAsyncThunk<any, any, { state: RootState }>(
  'shared/getFullLineTypesList',
  async (data = {}, thunkAPI) => {
    try {
      const params = { limit: 1000, page: 1, order: 'id' };
      Object.keys(data).forEach(key => {
        params[key] = data[key];
      });
      const queryString = Object.keys(params || {})
        .map(key => key + '=' + params[key])
        .join('&');

      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_URL}/line-types${queryString ? '?' + queryString : ''}`
      );
      return response.data?.items || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting full line types list!');
    }
  }
);

export const getFullVerifiedNumbersList = createAsyncThunk<any, any, { state: RootState }>(
  'shared/getFullVerifiedNumbersList',
  async (data = {}, thunkAPI) => {
    try {
      const params = { limit: 1000, page: 1, order: 'id' };
      Object.keys(data).forEach(key => {
        params[key] = data[key];
      });
      const queryString = Object.keys(params || {})
        .map(key => key + '=' + params[key])
        .join('&');

      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_URL}/verification/number${queryString ? '?' + queryString : ''}`
      );
      return response.data?.items || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting full numbers list!');
    }
  }
);

export const getFullVerifiedEmailsList = createAsyncThunk<any, any, { state: RootState }>(
  'shared/getFullVerifiedEmailsList',
  async (data = {}, thunkAPI) => {
    try {
      const params = { limit: 1000, page: 1, order: 'id' };
      Object.keys(data).forEach(key => {
        params[key] = data[key];
      });
      const queryString = Object.keys(params || {})
        .map(key => key + '=' + params[key])
        .join('&');

      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_URL}/verification/email${queryString ? '?' + queryString : ''}`
      );
      return response.data?.items || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting full emails list!');
    }
  }
);

export const getFullIssuesTrackerCodesList = createAsyncThunk<any, any, { state: RootState }>(
  'shared/getFullIssuesTrackerCodesList',
  async (data = {}, thunkAPI) => {
    try {
      const params = { limit: 1000, page: 1, order: 'id' };
      Object.keys(data).forEach(key => {
        params[key] = data[key];
      });
      const queryString = Object.keys(params || {})
        .map(key => key + '=' + params[key])
        .join('&');

      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_URL}/issue-tracker/codes${queryString ? '?' + queryString : ''}`
      );
      return response.data || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting full issue codes list!');
    }
  }
);

export const getFullCountriesList = createAsyncThunk<any, any, { state: RootState }>(
  'shared/getFullCountriesList',
  async (data = {}, thunkAPI) => {
    try {
      const mergedParams = { ...data };
      if (!mergedParams.org_id) {
        mergedParams.org_id =
          thunkAPI.getState().sharedReducer.selectedCompany.id === 'all'
            ? thunkAPI.getState().authReducer.user.organization.id
            : thunkAPI.getState().sharedReducer.selectedCompany.id;
      }

      const queryString = Object.keys(mergedParams || {})
        .map(key => key + '=' + mergedParams[key])
        .join('&');

      const cachedCountriesByOrganizationID = thunkAPI.getState().sharedReducer.cachedCountriesByOrganizationID;
      const cachedGroupedCountriesByOrganizationID =
        thunkAPI.getState().sharedReducer.cachedGroupedCountriesByOrganizationID;
      if (Object.keys(cachedCountriesByOrganizationID || {}).find(key => +key === +mergedParams.org_id)) {
        thunkAPI.dispatch(
          setFullGroupedCountriesList(cachedGroupedCountriesByOrganizationID[mergedParams.org_id] || [])
        );
        thunkAPI.dispatch(setFullCountriesList(cachedCountriesByOrganizationID[mergedParams.org_id] || []));
        return;
      }

      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_URL}/countries/short${queryString ? `?${queryString}` : ''}`
      );

      const groupedCountries = groupBy(response.data.items, 'category');
      const fullCountriesList = Object.keys(groupedCountries).map(key => ({
        name: key,
        options: groupedCountries[key],
      }));

      thunkAPI.dispatch(setFullGroupedCountriesList(fullCountriesList || []));
      thunkAPI.dispatch(setFullCountriesList(response.data.items || []));

      thunkAPI.dispatch(setCachedCountriesByOrganizationID({ [mergedParams.org_id]: response.data.items || [] }));
      thunkAPI.dispatch(setCachedGroupedCountriesByOrganizationID({ [mergedParams.org_id]: fullCountriesList || [] }));

      return response.data?.items || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting full countries list!');
    }
  }
);

export const getRouterProductNameTypesList = createAsyncThunk<any, any, { state: RootState }>(
  'shared/getRouterProductNameTypesList',
  async (data = {}, thunkAPI) => {
    try {
      const params = { limit: 1000, page: 1, order: 'id' };
      Object.keys(data).forEach(key => {
        params[key] = data[key];
      });
      const queryString = Object.keys(params || {})
        .map(key => key + '=' + params[key])
        .join('&');

      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_URL}/routers/product-names${queryString ? '?' + queryString : ''}`
      );
      return response.data?.items || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting full product names list!');
    }
  }
);

export const getFullAuditEntityTypes = createAsyncThunk<any, any, { state: RootState }>(
  'shared/getFullAuditEntityTypes',
  async (data = {}, thunkAPI) => {
    try {
      const params = { limit: 1000, page: 1, order: 'id' };
      Object.keys(data).forEach(key => {
        params[key] = data[key];
      });
      const queryString = Object.keys(params || {})
        .map(key => key + '=' + params[key])
        .join('&');

      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_URL}/tools/audit-logs/entities${queryString ? '?' + queryString : ''}`
      );
      return response.data || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting full entity types list!');
    }
  }
);

export const getFullExternalSystemList = createAsyncThunk<
  any,
  {
    type: 'TELCO' | 'PBX';
    organizationID: number | null;
    telcoID?: number;
  },
  { state: RootState }
>('shared/getFullExternalSystemList', async ({ type, telcoID, organizationID }, thunkAPI) => {
  try {
    const orgParam = !organizationID ? '' : `&org_id=${organizationID}`;
    const telcoParam = telcoID ? `&telcoID=${telcoID}` : '';
    const url = `${process.env.REACT_APP_API_URL}/external-systems?type=${type || ''}${orgParam}${telcoParam}`;

    const response = await axios.get<any>(url);

    if (type === 'PBX') thunkAPI.dispatch(setFullExternalSystemList(response.data || []));
    if (type === 'TELCO') thunkAPI.dispatch(setFullExternalProviderList(response.data || []));

    return response.data || [];
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting full external systems list!');
  }
});

export const getSIMCarrierList = createAsyncThunk<any, any, { state: RootState }>(
  'shared/getSIMCarrierList',
  async (data = {}, thunkAPI) => {
    try {
      const params = { limit: 1000, page: 1, order: 'id' };
      Object.keys(data).forEach(key => {
        params[key] = data[key];
      });
      const queryString = Object.keys(params || {})
        .map(key => key + '=' + params[key])
        .join('&');

      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_URL}/tc-sim/carrier${queryString ? '?' + queryString : ''}`
      );
      return response.data?.items || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting full carriers list!');
    }
  }
);

export const getFullProfileList = createAsyncThunk<any, any, { state: RootState }>(
  'shared/getFullProfileList',
  async (data = {}, thunkAPI) => {
    try {
      const params = { limit: 1000, page: 1, order: 'id' };
      Object.keys(data).forEach(key => {
        params[key] = data[key];
      });
      const queryString = Object.keys(params || {})
        .map(key => key + '=' + params[key])
        .join('&');

      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_URL}/atas/profiles/${queryString ? '?' + queryString : ''}`
      );
      return response.data || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting full profiles list!');
    }
  }
);
