import { EnclosureType } from '../../types/enclosure/enclosure.type';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { EnclosureTypeType } from '../../types/enclosure/enclosure-type/enclosure-type.type';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { additionalQueryParams } from '../../utils/utilities';
import { RootState } from '../../redux/Store';
import { setModalContent, setShowModal } from '../utils/utils.slice';
import { AsyncTaskType } from '../../types/async-task/async-task.type';

export const postEnclosures = createAsyncThunk<
  AsyncTaskType,
  {
    data: {
      warehouse: { id: number };
      equipment_group: { id: number };
      enclosures: Array<unknown>;
    };
  },
  { state: RootState }
>('enclosures/postEnclosures', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<AsyncTaskType>(`${process.env.REACT_APP_API_URL}/enclosures/bulk`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating enclosures!');
  }
});

export const postEnclosureDetails = createAsyncThunk<EnclosureType, { data: unknown }, { state: RootState }>(
  'enclosures/postEnclosureDetails',
  async ({ data }, thunkAPI) => {
    try {
      const response = await axios.post<EnclosureType>(`${process.env.REACT_APP_API_URL}/enclosures`, data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating enclosure!');
    }
  }
);

export const getEnclosuresList = createAsyncThunk<PaginationType<EnclosureType>, RouteParamsType, { state: RootState }>(
  'enclosures/getEnclosuresList',
  async (params, thunkAPI) => {
    try {
      const additionalParams = additionalQueryParams(
        thunkAPI.getState().sharedReducer.selectedCompany,
        thunkAPI.getState().sharedReducer.selectedLocation,
        thunkAPI.getState().sharedReducer.includeSuborgs,
        thunkAPI.getState().sharedReducer.selectedCountry
      );
      const mergedParams = { ...params, ...additionalParams };
      const queryString = Object.keys(mergedParams)
        .map(key => key + '=' + mergedParams[key])
        .join('&');

      const response = await axios.get<PaginationType<EnclosureType>>(
        `${process.env.REACT_APP_API_URL}/enclosures${queryString ? `?${queryString}` : ''}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching enclosures!');
    }
  }
);

export const getEnclosureDetails = createAsyncThunk<EnclosureType, { id: number }, { state: RootState }>(
  'enclosures/getEnclosureDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<EnclosureType>(`${process.env.REACT_APP_API_URL}/enclosures/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching enclosure!');
    }
  }
);

export const patchEnclosureDetails = createAsyncThunk<
  EnclosureType,
  { id: number; data: unknown },
  {
    state: RootState;
  }
>('enclosures/patchEnclosureDetails', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<EnclosureType>(`${process.env.REACT_APP_API_URL}/enclosures/${id}`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating enclosure!');
  }
});

export const deleteEnclosureDetails = createAsyncThunk<
  EnclosureType,
  { id: number; fetchList: boolean },
  { state: RootState }
>('enclosures/deleteEnclosureDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<EnclosureType>(`${process.env.REACT_APP_API_URL}/enclosures/${id}`);
    if (fetchList) thunkAPI.dispatch(getEnclosuresList(thunkAPI.getState().enclosuresReducer.enclosuresRouteParams));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting enclosure!');
  }
});

// ENCLOSURE TYPES

export const postEnclosureTypeDetails = createAsyncThunk<EnclosureTypeType, { data: unknown }, { state: RootState }>(
  'enclosureTypes/postEnclosureTypeDetails',
  async ({ data }, thunkAPI) => {
    try {
      const response = await axios.post<EnclosureTypeType>(`${process.env.REACT_APP_API_URL}/enclosure-types`, data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating enclosure type!');
    }
  }
);

export const getEnclosureTypesList = createAsyncThunk<
  PaginationType<EnclosureTypeType>,
  RouteParamsType,
  { state: RootState }
>('enclosureTypes/getEnclosureTypesList', async (params, thunkAPI) => {
  try {
    const additionalParams = additionalQueryParams(
      thunkAPI.getState().sharedReducer.selectedCompany,
      thunkAPI.getState().sharedReducer.selectedLocation,
      thunkAPI.getState().sharedReducer.includeSuborgs
    );
    const mergedParams = { ...params, ...additionalParams };
    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<EnclosureTypeType>>(
      `${process.env.REACT_APP_API_URL}/enclosure-types${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching enclosureTypes!');
  }
});

export const getEnclosureTypeDetails = createAsyncThunk<EnclosureTypeType, { id: number }, { state: RootState }>(
  'enclosureTypes/getEnclosureTypeDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<EnclosureTypeType>(`${process.env.REACT_APP_API_URL}/enclosure-types/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching enclosure type!');
    }
  }
);

export const patchEnclosureTypeDetails = createAsyncThunk<
  EnclosureTypeType,
  { id: number; data: unknown },
  {
    state: RootState;
  }
>('enclosureTypes/patchEnclosureTypeDetails', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<EnclosureTypeType>(
      `${process.env.REACT_APP_API_URL}/enclosure-types/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating enclosure type!');
  }
});

export const deleteEnclosureTypeDetails = createAsyncThunk<
  EnclosureTypeType,
  { id: number; fetchList: boolean },
  { state: RootState }
>('enclosureTypes/deleteEnclosureTypeDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<EnclosureTypeType>(`${process.env.REACT_APP_API_URL}/enclosure-types/${id}`);
    if (fetchList)
      thunkAPI.dispatch(getEnclosureTypesList(thunkAPI.getState().enclosuresReducer.enclosureTypesRouteParams));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting enclosure type!');
  }
});
